import {
  ASSIGNMENT_ID_SET
} from './mle-actions';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case ASSIGNMENT_ID_SET: {
      return action.id;
    }

    default:
      return state;
  }
};
