import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import ReactGa from 'react-ga';
import Spinner from '../../components/spinner/spinner';
import ErrorHandling from '../error-handling/error-handling';
import InternetConnectivity from '../internet-connectivity/internet-connectivity';
import Modal from '../modal/modal';
import css from './mle.scss';
import Activity from '../activity/activity';
import StudentProgress from '../student-progress/student-progress';
import Header from '../header/header';
import Footer from '../footer/footer';
import Resources from '../../helpers/resources';
import DetectDevtools from '../devtools-detect/devtools-detect';
import DevToolsMessage from '../devtools-detect/devtools-message';
import { useAssignUpf } from './mle-hooks';
import { getValueFromUrl } from '../../helpers/utils';
import { FooterQtc } from '../footer-qtc/footer-qtc';
import { useNewContent } from '../conquest/hooks/use-new-content';
import { KeypadContainer } from '../conquest/keypad-container';

const Mle = () => {
  const { spinnerReducer, mleReducer, conquestReducer } = useSelector(state => state);
  const [googleAnalyticsId, setGoogleAnalyticsId] = useState(false);
  const { isDevToolsOpen } = useSelector(state => state.detectDevToolsReducer);
  const standard = _.get(mleReducer, 'standardDescription', '');
  const journey = _.get(mleReducer, 'description', '');
  const firstName = _.get(mleReducer, 'user.firstName', '');
  const getMathsAZUrl = () => {
    const locale = getValueFromUrl('locale') || 'en-AU';
    return `${window._env_.mathsAZUrl}?authToken=${Resources.env.authToken}&locale=${locale}`;
  };

  const getGoogleAnalyticsId = async () => {
    try {
      const id = await Resources.env.substituteConfigurationItems('@GoogleTagManagerID');
      setGoogleAnalyticsId(id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Error acquiring Google Tag manager ID!');
    }
  };

  useAssignUpf();
  useNewContent();

  useEffect(() => {
    if (googleAnalyticsId) {
      ReactGa.initialize(googleAnalyticsId);
    }
  }, [googleAnalyticsId]);

  useEffect(() => {
    getGoogleAnalyticsId();
    ReactGa.event({
      category: 'UPF init',
      action: 'UPF init',
      nonInteraction: true
    });
  }, []);

  return (
    <Fragment>
      <KeypadContainer />
      <DetectDevtools />
      <ErrorHandling />
      <Modal />
      <InternetConnectivity />
      {isDevToolsOpen ?
        <DevToolsMessage /> :
        <div className={css.mle}>
          <Header
            journey={journey}
            standard={standard}
            firstName={firstName}
            mathsAZUrl={getMathsAZUrl()}
          />
          <div className={css.content}>
            <Activity />
            <StudentProgress />
          </div>
          {
            conquestReducer.isNewContent ? <FooterQtc /> : <Footer />
          }
          <Spinner show={spinnerReducer.length > 0 || false} />
        </div>
      }
    </Fragment>
  );
};

export default Mle;
