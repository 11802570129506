exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".chakra-stack.footer-qtc---1OlZO{height:80px;max-width:1200px;width:100%;align-self:center;align-items:flex-start}.chakra-stack.footer-qtc---1OlZO .activity-side---fyp27{width:calc(100% - 275px);margin:0 5px 0 40px;justify-content:space-between}.chakra-stack.footer-qtc---1OlZO .progress-side---1SSu0{width:185px;margin:0 40px 0 5px;margin:unset;margin-left:17px}", ""]);

// exports
exports.locals = {
	"footer-qtc": "footer-qtc---1OlZO",
	"activity-side": "activity-side---fyp27",
	"progress-side": "progress-side---1SSu0"
};