import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { t, Trans } from '@lingui/macro';
import ReactGa from 'react-ga';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import css from './footer.scss';
import { BtnText } from '../../components/buttons';
import { markActivity, nextActivity, updateQuestion } from '../activity/activity-actions';
import { isMarkActivityDisabled, getDifferenceInSeconds } from '../activity/activity-utils';
import { constructModal, activateModal } from '../modal/modal-actions';
import { ModalGeneric } from '../../components/modal-content';
import InfoFeedback from '../info-feedback/info-feedback';
import { enableSpinner } from '../mle/mle-actions';
import { LEARNOSITY_SPINNER } from '../../helpers/constants';
import Info from '../info/info';
import { getValueFromUrl } from '../../helpers/utils';

const Footer = () => {
  const {
    isMarked, questions, isLoading, isFetching, userResponse, needAttention, stepsCompleted, isInitialQuestion,
    isActivityActive, startDate
  } = useSelector(state => state.activityReducer);
  const { stepCount } = useSelector(state => state.mleReducer || {});
  const activeModals = useSelector(state => state.modalReducer);
  const { assignmentIdReducer } = useSelector(state => state);
  const dispatch = useDispatch();
  const { i18n } = useLocalisation();

  const closeModal = () => {
    dispatch(activateModal(false));
  };

  const saveAndCloseUpf = () => {
    ReactGa.event({
      category: 'Button - Save and close',
      action: 'Save and close UPF',
      label: 'UPF student interaction'
    });
    if (window.history.length === 1) {
      window.close();
    } else {
      window.history.back();
    }
  };

  const showBadStreakModal = () => {
    const modalData = {
      subHeader: i18n._(t`Looks like you might need some extra help with this topic!`),
      paragraph: `${i18n._(t`Your teacher may come and help you.`)}\n
      ${i18n._(t`You can still keep going with this quest in the meantime if you like.`)}`,
      buttons: [
        {
          text: i18n._(t`Ok`),
          callBack: closeModal,
          size: 'medium'
        }
      ]
    };

    dispatch(constructModal({
      activate: true,
      content: <ModalGeneric modalData={modalData} />,
      canvasOnClick: false
    }));
  };

  useEffect(() => {
    if (needAttention && !isInitialQuestion) {
      showBadStreakModal();
    }
  }, [needAttention]);

  const checkMyAnswerClick = () => {
    dispatch(markActivity(userResponse));
    dispatch(updateQuestion({
      assignmentId: getValueFromUrl('assignmentId') || assignmentIdReducer,
      locale: getValueFromUrl('locale'),
      questionId: questions[0].id,
      userResponse,
      timeToAnswerSec: getDifferenceInSeconds({
        startDate,
        endDate: new Date()
      })
    }));
    ReactGa.event({
      category: 'Button - Check my answer',
      action: 'Mark the question',
      label: 'UPF student interaction'
    });
  };

  const nextButtonClick = () => {
    dispatch(nextActivity());
    dispatch(enableSpinner(LEARNOSITY_SPINNER));
    ReactGa.event({
      category: 'Button - Next',
      action: 'Moving on to the next question',
      label: 'UPF student interaction'
    });
  };

  useEffect(() => {
    const keyUpHandler = (event) => {
      if (userResponse && event.key === 'Enter' && !isMarkActivityDisabled({ isLoading, userResponse })) {
        if (activeModals.length || !isActivityActive) {
          return;
        }
        if (isMarked) {
          if (isFetching || (stepCount && (stepCount === stepsCompleted))) {
            return;
          }
          nextButtonClick();
        } else {
          checkMyAnswerClick();
        }
      }
    };
    window.addEventListener('keyup', keyUpHandler);
    return () => {
      window.removeEventListener('keyup', keyUpHandler);
    };
  }, [userResponse, isLoading, isMarked, isFetching, stepCount, stepsCompleted, activeModals, isActivityActive]);

  return (
    <div className={css.footer}>
      <div className={css['activity-side']}>
        <div className={css['misc-container']}>
          <Info />
          <InfoFeedback
            className={css['info-feedback-margin']}
            questions={questions}
          />
        </div>
        <div className={css['btn-cont']}>
          {isMarked ? (<BtnText
            className={css.btn}
            callBack={nextButtonClick}
            isDisabled={isFetching || (stepCount && (stepCount === stepsCompleted))}
            type="secondary"
          >
            <Trans>Next</Trans>
          </BtnText>) :
            (<BtnText
              className={css.btn}
              callBack={checkMyAnswerClick}
              isDisabled={isMarkActivityDisabled({ isLoading, userResponse })}
              type="secondary"
            >
              <Trans>Check my answer</Trans>
            </BtnText>)
          }
        </div>
      </div>
      <div className={css['progress-side']}>
        <BtnText
          className={css.btn}
          callBack={saveAndCloseUpf}
        >
          <Trans>Save and close</Trans>
        </BtnText>
      </div>
    </div>
  );
};

export default Footer;

