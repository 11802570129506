import React from 'react';
import PropTypes from 'prop-types';
import css from './triangle.scss';

const Triangle = ({
  className,
  leftPx,
  rightPx,
  topPx,
  color
}) => (
  <div
    className={`${css.triangle} ${className || ''}`}
    style={{
      borderLeft: `${leftPx}px solid transparent`,
      borderRight: `${rightPx}px solid transparent`,
      borderTop: `${topPx}px solid ${color}`
    }}
  />
);

Triangle.propTypes = {
  className: PropTypes.string,
  leftPx: PropTypes.string,
  rightPx: PropTypes.string,
  topPx: PropTypes.string,
  color: PropTypes.string
};

export default Triangle;
