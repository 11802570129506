import {
  GET_ASSIGNMENT_DETAILS_SUCCESS
} from './mle-actions';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSIGNMENT_DETAILS_SUCCESS: {
      return action.payload;
    }

    default:
      return state;
  }
};
