exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".modal-generic---YmVhb{width:412px;padding:20px 20px 0;white-space:pre-wrap;text-align:center;line-height:21px}.modal-generic---YmVhb h2{margin-bottom:20px;color:#3477c7}.modal-generic---YmVhb h4{font-weight:bold;margin-bottom:20px;line-height:24px}.modal-generic---YmVhb .modal-buttons---2bv6A{display:flex;justify-content:space-around;margin-top:20px}.modal-generic---YmVhb .modal-buttons---2bv6A.multiple---34XMY{justify-content:space-between}", ""]);

// exports
exports.locals = {
	"modal-generic": "modal-generic---YmVhb",
	"modal-buttons": "modal-buttons---2bv6A",
	"multiple": "multiple---34XMY"
};