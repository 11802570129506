import { SET_DEV_TOOLS_STATUS } from './devtools-detect-actions';

const initialState = {
  isDevToolsOpen: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DEV_TOOLS_STATUS:
      return {
        isDevToolsOpen: action.status
      };
    default:
      return state;
  }
};
