exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".footer---29rdj{height:80px;display:flex;align-items:flex-start;z-index:100;max-width:1200px;width:100%;margin:0 auto}.footer---29rdj .activity-side---2N8uv{display:flex;justify-content:space-between;width:calc(100% - 275px);margin:0 5px 0 40px}.footer---29rdj .activity-side---2N8uv .misc-container---38GNw{z-index:1;display:flex;align-items:center}.footer---29rdj .activity-side---2N8uv .misc-container---38GNw .info-feedback-margin---2g-_Z{margin-left:20px}.footer---29rdj .activity-side---2N8uv .btn-cont---RjunB{display:flex;margin-left:20px}.footer---29rdj .progress-side---1EpzM{width:185px;margin:0 40px 0 5px;display:flex;justify-content:flex-end}", ""]);

// exports
exports.locals = {
	"footer": "footer---29rdj",
	"activity-side": "activity-side---2N8uv",
	"misc-container": "misc-container---38GNw",
	"info-feedback-margin": "info-feedback-margin---2g-_Z",
	"btn-cont": "btn-cont---RjunB",
	"progress-side": "progress-side---1EpzM"
};