import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tween } from 'react-gsap';
import classNames from 'classnames';
import css from './flare.scss';

const Flare = ({ className, delay = 1.2 }) => {
  const [completeAnim, setCompleteAnim] = useState(false);

  const handleCompleteAnim = () => {
    if (!completeAnim) {
      setCompleteAnim(true);
    }
  };

  return (
    <div
      className={classNames(css.flare, className)}
    >
      <Tween
        from={completeAnim ? {
          opacity: 1
        } : {
          opacity: 0
        }}
        to={completeAnim ? {
          opacity: 0,
          duration: 0.5,
          delay: 1.7
        } : {
          opacity: 1,
          duration: 0.5,
          delay
        }}
        onComplete={handleCompleteAnim}
      >
        <div className={css.inner}>
          <Tween
            from={{
              rotation: 45
            }}
            to={{
              rotation: 405,
              duration: 1,
              delay
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
            >
              <defs>
                <filter id="blur1">
                  <feGaussianBlur stdDeviation="1.5" />
                </filter>
                <filter id="blur3">
                  <feGaussianBlur stdDeviation="3" />
                </filter>
              </defs>
              <Tween
                from={{
                  opacity: 0
                }}
                to={{
                  opacity: 0.6,
                  duration: 0.6,
                  delay
                }}
              >
                <g id="line1">
                  <g>
                    <polygon className="st0" points="50,0 47,50 50,100 53,50" filter="url(#blur1)" />
                  </g>
                </g>
              </Tween>
              <Tween
                from={{
                  opacity: 0.2
                }}
                to={{
                  opacity: 0.7,
                  duration: 1,
                  delay
                }}
              >
                <g id="line2">
                  <g>
                    <polygon className="st0" points="100,50 50,47 0,50 50,53" filter="url(#blur1)" />
                  </g>
                </g>
              </Tween>
              <g id="round">
                <g>
                  <circle className="st0" cx="50" cy="50" r="16" filter="url(#blur3)" />
                </g>
              </g>
            </svg>
          </Tween>
        </div>
      </Tween>
    </div>
  );
};

Flare.propTypes = {
  className: PropTypes.string,
  delay: PropTypes.number
};

export default Flare;
