import iconTick from '../assets/images/icon_tick_01.svg';
import iconCross from '../assets/images/icon_cross_01.svg';

export const LOCALE_DEFAULT = 'en-AU';

export const resourceEndPoints = {
  getAssignmentDetails: '/assignmentDetails',
  getQuestion: '/getQuestion',
  saveQuestionResult: '/saveQuestionResult',
  saveQuestionResultQtc: '/saveQuestionResultQtc',
  createAssignment: '/assignLearningJourney'
};

export const LANDING_SPINNER = 'LANDING_SPINNER';
export const LEARNOSITY_SPINNER = 'LEARNOSITY_SPINNER';
export const ASSIGNMENT_ID_SPINNER = 'ASSIGNMENT_ID_SPINNER';

export const MAX_RESPONSES = 8;
export const NO_RESPONSE = -1;
export const CORRECT_RESPONSE = 1;
export const INCORRECT_RESPONSE = 0;

export const ICON_RESPONSES = {
  [CORRECT_RESPONSE]: iconTick,
  [INCORRECT_RESPONSE]: iconCross
};
