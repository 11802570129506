exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".triangle---3obyx{width:0;height:0}", ""]);

// exports
exports.locals = {
	"triangle": "triangle---3obyx"
};