import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { removeLearnosityKeypad } from '../../helpers/utils';
import css from './ducs.scss';
import { subscribeToEvents, ACTIVITY_QUESTION_MARKED, formatResponses } from './ducs-utils';
import { getDucsConfig, setDucsInstanceId } from './ducs-actions';
import { activateError } from '../error-handling/error-handling-actions';
import { LOCALE_DEFAULT } from '../../helpers/constants';
import LoadScript from '../../components/load-script/load-script';

const Ducs = (
  {
    pageId,
    locale = LOCALE_DEFAULT,
    layoutVersion,
    contentVersion,
    isMarked = false,
    onChange = () => { },
    onLoad = () => { },
    loadNext = false,
    userResponse = {},
    activateAutoFocus = false
  }
) => {
  const dispatch = useDispatch();
  const { url, config, instanceId } = useSelector(state => state.ducsReducer);

  const initialise = () => {
    const responses = formatResponses(userResponse);
    const learnosityState = isMarked ? 'review' : 'resume';
    const id = window.DUCSApplication.initialise('.js-ducs', {
      configuration: config,
      pageId,
      locale,
      layoutVersion,
      contentVersion,
      learnosityInitialState: {
        state: !Object.keys(responses).length ? 'initial' : learnosityState,
        useDefaultFeedback: true,
        responses
      },
      activateAutoFocus
    });
    dispatch(setDucsInstanceId(id));
    subscribeToEvents(
      {
        subscribe: (event, fn, params) => {
          window.DUCSApplication.subscribe(id, event, fn, params);
        },
        dispatch,
        onChange,
        onLoad
      }
    );
  };

  useEffect(() => {
    dispatch(getDucsConfig());
  }, []);

  useEffect(() => {
    if (loadNext) {
      removeLearnosityKeypad();
    }
    if (instanceId && loadNext) {
      window.DUCSApplication.updateOptions(instanceId, {
        pageId,
        locale,
        layoutVersion,
        contentVersion,
        learnosityInitialState: {
          state: 'initial',
          useDefaultFeedback: true,
          responses: {}
        },
        randomId: Math.random(),
        activateAutoFocus
      });
    }
  }, [loadNext]);

  useEffect(() => {
    if (isMarked) {
      window.DUCSApplication.publish('.js-ducs', ACTIVITY_QUESTION_MARKED, {});
    }
  }, [isMarked]);

  return (
    <div className={css.ducs}>
      {url && config && !instanceId && <LoadScript
        url={url}
        onLoad={initialise}
        onError={() => dispatch(activateError(500))}
      />}
      <div className="js-ducs" />
    </div>
  );
};

Ducs.propTypes = {
  pageId: PropTypes.number.isRequired,
  locale: PropTypes.string,
  layoutVersion: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contentVersion: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isMarked: PropTypes.bool,
  onChange: PropTypes.func,
  onLoad: PropTypes.func,
  loadNext: PropTypes.bool,
  userResponse: PropTypes.object,
  activateAutoFocus: PropTypes.bool
};

export default Ducs;
