import _ from 'lodash';
import { activateError } from '../error-handling/error-handling-actions';

export const ACTIVITY_QUESTION_CHANGED = 'ACTIVITY_QUESTION_CHANGED';
export const ERROR_EVENT_DISPATCHED = 'ERROR_EVENT_DISPATCHED';
export const PAGE_LOADED = 'PAGE_LOADED';
export const ACTIVITY_QUESTION_MARKED = 'ACTIVITY_QUESTION_MARKED';

export const subscribeToEvents = ({ subscribe, dispatch, onChange, onLoad }) => {
  subscribe(ACTIVITY_QUESTION_CHANGED, ({ params }) => {
    onChange(params);
  }, { onlyCurrentData: true });
  subscribe(ERROR_EVENT_DISPATCHED, () => {
    dispatch(activateError(500));
  });
  subscribe(PAGE_LOADED, () => {
    onLoad();
  });
};

export const formatResponses = (userResponse) => {
  const responses = {};

  if (!_.isEmpty(userResponse)) {
    Object.keys(userResponse).forEach((key) => {
      if (key === 'isAttempted' || key === 'isCorrect' || key === 'total') return;
      const { value, type, apiVersion, revision } = _.get(userResponse[key], 'user_response') || {};
      responses[key] = {
        value,
        type,
        apiVersion,
        revision,
        responses: _.get(userResponse[key], 'user_response.responses', []),
        updatedFormat: true
      };
    });
  }
  return responses;
};
