import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import UserFeedback from '@3plearning/user-feedback';
import classNames from 'classnames';
import { t } from '@lingui/macro';
import _ from 'lodash';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import feedbackIcon from '../../assets/images/3PLogo.svg';
import { getUserFeedbackConfig, getVoteItems, getTipText, handleClick } from './info-feedback-util';
import css from './info-feedback.scss';
import { getValueFromUrl } from '../../helpers/utils';
import Resources from '../../helpers/resources';
import InfoIcon from '../../components/icon-info/info-icon';
import { constructModal, activateModal } from '../modal/modal-actions';
import { ModalGeneric } from '../../components/modal-content';
import { setActivityActive } from '../activity/activity-actions';

const InfoFeedback = ({ className, questions }) => {
  const feedbackRef = useRef(null);
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState(null);
  const [active, setActive] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const [userFeedbackConfig, setUserFeedbackConfig] = useState(null);
  const isTeacher = getValueFromUrl('isPreview') === 'true';
  const girlId = _.get(questions[0], 'id', '');
  const { i18n } = useLocalisation();
  const { assignmentIdReducer } = useSelector(state => state);

  const handleAllClicks = (e, withinFeedback) => {
    /**
     * e                      | withinFeedback  | source of click
     * ---------------------------------------------------------------------
     * undefined              | false           | window
     * .target.name undefined | false           | InfoIcon btn
     * .target.name undefined | true            | UserFeedback non buttons
     * .target.name defined   | true            | UserFeedback buttons
     */
    const name = e && (e.target.name || true);
    const nextState = handleClick(showTip, active, withinFeedback, name, activeItem);
    setActive(nextState.active);
    setShowTip(nextState.showTip);
  };

  const handleSuccess = () => {
    setActive(false);
    if (isTeacher) {
      const modalData = {
        header: i18n._(t`Thank you!`),
        // eslint-disable-next-line max-len
        subHeader: i18n._(t`Your feedback has been successfully submitted. We greatly appreciate your time to let us know your thoughts.`),
        buttons: [
          {
            text: i18n._(t`Ok`),
            callBack: () => { dispatch(activateModal(false)); },
            size: 'medium'
          }
        ]
      };

      dispatch(constructModal({
        activate: true,
        content: <ModalGeneric modalData={modalData} />,
        canvasOnClick: false
      }));
    }
  };

  useEffect(() => {
    (async () => {
      const config = await getUserFeedbackConfig();
      setUserFeedbackConfig(config);
    })();
  }, []);

  useEffect(() => {
    dispatch(setActivityActive(!active));
  }, [active]);

  return (
    <div className={classNames(css['info-feedback'], className)}>
      <div ref={feedbackRef}>
        {userFeedbackConfig && <UserFeedback
          className={classNames(css['user-feedback'], { [css['user-feedback-active']]: active })}
          ratingIdentifier={userFeedbackConfig.identifier}
          localeCdnBaseUrl={userFeedbackConfig.localeCdnBaseUrl}
          locale={getValueFromUrl('locale')}
          itemId={girlId}
          authToken={Resources.env.authToken || ''}
          ratingEndPoint={userFeedbackConfig.url}
          expanded={active}
          voteItemsActivation={getVoteItems(isTeacher)}
          onSuccess={handleSuccess}
          onError={() => setActive(false)}
          onClick={e => handleAllClicks(e, true)}
          data={{ assignmentId: getValueFromUrl('assignmentId') || assignmentIdReducer }}
          setActiveItem={setActiveItem}
        />}
      </div>
      <InfoIcon
        className={classNames(css['btn-info'], { [css['btn-info-active']]: active })}
        infoIconData={{
          icon: feedbackIcon,
          paragraph: getTipText(isTeacher),
          showTip,
          ignoredRef: feedbackRef,
          onClick: handleAllClicks
        }}
      />
    </div>
  );
};

InfoFeedback.propTypes = {
  className: PropTypes.string,
  questions: PropTypes.array
};

export default InfoFeedback;
