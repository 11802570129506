import { useEffect } from 'react';

const loadedScripts = [];

const LoadScript = ({ url, onLoad, onError }) => {
  useEffect(() => {
    if (!loadedScripts.includes(url)) {
      const scriptElement = window.document.createElement('script');
      scriptElement.src = url;
      scriptElement.onload = onLoad;
      scriptElement.onerror = onError;
      scriptElement.async = true;
      window.document.body.appendChild(scriptElement);
    } else {
      onLoad();
    }
  }, []);
  return null;
};

export default LoadScript;
