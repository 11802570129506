exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".points---3Mm6r{display:flex;flex-direction:column;align-items:center;color:#fff;width:100%}.points---3Mm6r h1{line-height:32px}.points---3Mm6r .label---2WANa{text-align:center;line-height:14px;width:100%}", ""]);

// exports
exports.locals = {
	"points": "points---3Mm6r",
	"label": "label---2WANa"
};