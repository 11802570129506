/* eslint-disable quotes */ 

import { extendTheme } from '@chakra-ui/react';

const base = {
  h: '50px',
  fontSize: '16px',
  px: '30px',
  borderRadius: '25px'
};

export const mleTheme = extendTheme({
  fonts: {
    heading: `'Muli', sans-serif`,
    body: `'Muli', sans-serif`
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        color: 'white'
      },
      variants: {
        mle: {
          ...base,
          bg: '#3477c7',
          _hover: {
            bg: '#fd7849',
            _disabled: {
              bg: '#3477c7'
            }
          }
        },
        mleInvert: {
          ...base,
          bg: '#fd7849',
          _hover: {
            bg: '#3477c7',
            _disabled: {
              bg: '#fd7849'
            }
          }
        }
      }
    }
  }
});
