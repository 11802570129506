import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import InlineSVG from 'react-inlinesvg';
import classNames from 'classnames';
import css from './progress-tower.scss';
import { NO_RESPONSE, ICON_RESPONSES } from '../../helpers/constants';
import Points from '../points/points';
import getDisplayResponsesFromAttemptedResponses from './student-progress-utils';
import { BtnIcon } from '../../components/buttons';
import arrow from '../../assets/images/arrow.svg';
import { toggleNarrowStudentProgress } from './student-progress-actions';

const ProgressTower = ({ points, streak, narrowToggle = false, isFetching = false }) => {
  const dispatch = useDispatch();
  return (
    <Fragment>
      <div className={classNames(css['tower-top'], css['tower-base'])} />
      <div className={classNames(css['progress-outer'], { [css['narrow-toggle']]: !narrowToggle })}>
        <div className={css['progress-inner']}>
          <div className={css['response-dots']}>
            {
              getDisplayResponsesFromAttemptedResponses(streak, isFetching).map((response, index) => {
                const key = `${response}-${index}`;
                return (
                  <span
                    className={classNames(
                      css['response-indicator'],
                      { [css['blank-response']]: response === NO_RESPONSE }
                    )}
                    key={key}
                  >
                    {response !== NO_RESPONSE && <InlineSVG src={ICON_RESPONSES[response]} />}
                  </span>
                );
              })
            }
          </div>
          <Points value={points} />
          <BtnIcon
            icon={arrow}
            className={css['arrow-up']}
            callBack={() => dispatch(toggleNarrowStudentProgress())}
          />
        </div>
      </div>
      <div className={css['tower-base']} />
    </Fragment>
  );
};

ProgressTower.propTypes = {
  points: PropTypes.number.isRequired,
  streak: PropTypes.array.isRequired,
  narrowToggle: PropTypes.bool,
  isFetching: PropTypes.bool
};

export default ProgressTower;
