/* eslint-disable react/no-array-index-key */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import InlineSvg from 'react-inlinesvg';
import css from './gem-holders.scss';
import merit from '../../../assets/images/hex_coin.svg';
import meritHolder from '../../../assets/images/hex_coin_blank.svg';
import arrow from '../../../assets/images/arrow.svg';
import { BtnIcon } from '../../buttons';
import { toggleNarrowStudentProgress } from '../../../containers/student-progress/student-progress-actions';

const GemHolders = () => {
  const activityReducer = useSelector(state => state.activityReducer);
  const mleReducer = useSelector(state => state.mleReducer);
  const stepCount = _.get(mleReducer, 'stepCount');
  const stepsCompleted = _.get(activityReducer, 'stepsCompleted');
  const { narrowToggle } = useSelector(state => state.studentProgressReducer);

  const dispatch = useDispatch();

  return (
    <div
      className={
        classNames(
          css['gem-holders'],
          { [css['narrow-toggle']]: !narrowToggle }
        )
      }
    >
      {
        [...Array(stepCount)].map((item, count) => (
          <div
            key={`${count}`}
            className={css['gem-holder']}
          >
            <InlineSvg
              src={meritHolder}
            />
            {count < stepsCompleted &&
              <InlineSvg
                src={merit}
              />
            }
          </div>
        ))
      }
      <BtnIcon
        icon={arrow}
        className={css.arrow}
        callBack={() => dispatch(toggleNarrowStudentProgress())}
      />
    </div>
  );
};

export default GemHolders;
