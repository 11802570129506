import React from 'react';
import PropTypes from 'prop-types';
import { Tween } from 'react-gsap';
import { Trans } from '@lingui/macro';
import GemsAnimation from './gems-animation';
import css from './congratulations.scss';
import CongratulationsText from './congratulations-text';
import { COIN_DELAY, COIN_DURATION, EASE, TEXT_DURATION, TEXT_OFFSET } from './congratulations-utils';

const Congratulations = ({ description, stepCount }) => {
  if (!stepCount) {
    return null;
  }

  return (
    <div className={css.congratulations}>
      <div className={css.curtains}>
        <Tween
          from={{
            y: -TEXT_OFFSET
          }}
          to={{
            y: 0
          }}
          duration={TEXT_DURATION}
          ease={EASE}
        >
          <div className={css.top} />
        </Tween>
        <Tween
          from={{
            y: TEXT_OFFSET
          }}
          to={{
            y: 0
          }}
          duration={TEXT_DURATION}
          ease={EASE}
        >
          <div className={css.bottom} />
        </Tween>
      </div>
      <div className={css.content}>
        <CongratulationsText />
        <Tween
          from={{
            opacity: 0
          }}
          to={{
            opacity: 1
          }}
          duration={TEXT_DURATION}
          ease={EASE}
        >
          <h4 className={css.toast}>
            <Trans>You have mastered</Trans><br />
            <strong>{description}</strong><br />
            <Trans>You have earned Merits to use in Meritopia!</Trans>
          </h4>
        </Tween>
        {
          stepCount && <GemsAnimation stepCount={stepCount} />
        }
        <br />
        <Tween
          from={{
            opacity: 0
          }}
          to={{
            opacity: 1
          }}
          duration={COIN_DURATION}
          delay={TEXT_DURATION + (stepCount * (COIN_DELAY + COIN_DURATION))}
          ease={EASE}
        >
          <h4 className={css.toast}>
            <Trans>Press <strong>Save &amp; Close</strong> to exit</Trans>
          </h4>
        </Tween>
      </div>
    </div>
  );
};

Congratulations.propTypes = {
  description: PropTypes.string,
  stepCount: PropTypes.number
};

export default Congratulations;
