import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getValueFromUrl } from '../../../helpers/utils';
import { setIsNewContent } from '../conquest.actions';

export const useNewContent = () => {
  const dispatch = useDispatch();
  const { questions } = useSelector(state => state.activityReducer);

  useEffect(() => {
    if (_.has(questions[0], 'conquest.questionVariant') && getValueFromUrl('newContent')) {
      dispatch(setIsNewContent(true));
    } else {
      dispatch(setIsNewContent(false));
    }
  }, [questions[0]]);
};
