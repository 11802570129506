import { setupI18n } from '@lingui/core';
import { loadLinguiCatalogs } from './generate-catalogs';
import { getValueFromUrl } from '../utils';

// manually set up i18n for jslingui, to be used outside react components
// language should be dynamically loaded
export async function getI18N(baseUrl, locales, localeOverride) {
  return i18nSetup(baseUrl, locales, localeOverride);
}

export async function i18nSetup(baseUrl, locales, localeOverride) {
  const catalogs = await loadLinguiCatalogs(baseUrl, locales);

  // get locale from query parameter, default to en-AU
  const locale = localeOverride || getValueFromUrl('locale') || 'en-AU';

  const i18n = setupI18n({
    language: locale,
    catalogs
  });

  // console.log('i18nSetup() - i18n =', JSON.stringify(i18n, null, 2));

  window.i18n = i18n;
  window.localisation = { i18n, locale };

  return i18n;
}
