exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".congratulations-text---3HhEs{position:relative}.congratulations-text---3HhEs .decorations---2j2MR{position:absolute;width:100%;height:100%}.congratulations-text---3HhEs .decorations---2j2MR .circle---39gkW{position:absolute;border-radius:100%}.congratulations-text---3HhEs .decorations---2j2MR .circle---39gkW span{display:flex}.congratulations-text---3HhEs .decorations---2j2MR .circle---39gkW svg{width:10px;height:10px}.congratulations-text---3HhEs .decorations---2j2MR .circle---39gkW.small---1OT7g svg{width:5px;height:5px}.congratulations-text---3HhEs .decorations---2j2MR .circle---39gkW.small---1OT7g svg circle{stroke-width:3px}.congratulations-text---3HhEs .decorations---2j2MR .circle---39gkW.yellow---2ZtjR svg circle{stroke:#ffe600}.congratulations-text---3HhEs .decorations---2j2MR .star---35zza{position:absolute}.congratulations-text---3HhEs .decorations---2j2MR .star---35zza span{display:flex}.congratulations-text---3HhEs .decorations---2j2MR .star---35zza svg{width:22px;height:22px}.congratulations-text---3HhEs .decorations---2j2MR .star---35zza.small---1OT7g svg{width:13px;height:13px}.congratulations-text---3HhEs .decorations---2j2MR .star---35zza.rotate---2THU9 svg{transform:rotate(45deg)}.congratulations-text---3HhEs .decorations---2j2MR .star---35zza.yellow---2ZtjR path{fill:#ffe600}.congratulations-text---3HhEs text{fill:#fd7849;text-transform:uppercase;font-size:10px;font-weight:bold;font-family:\"poetsen-one-regular\",arial,sans-serif}", ""]);

// exports
exports.locals = {
	"congratulations-text": "congratulations-text---3HhEs",
	"decorations": "decorations---2j2MR",
	"circle": "circle---39gkW",
	"small": "small---1OT7g",
	"yellow": "yellow---2ZtjR",
	"star": "star---35zza",
	"rotate": "rotate---2THU9"
};