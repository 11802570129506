exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".tip---y-RBM .dialogue---3CDPZ{padding:12px 14px;border-radius:6px;width:216px;box-shadow:0 1px 0 0 rgba(0,0,0,.2);background-color:#b2e1e3;position:absolute;transform:translateX(-50%);bottom:39px}.tip---y-RBM .dialogue---3CDPZ.left---NleWS{transform:translate(-20%)}.tip---y-RBM .dialogue---3CDPZ .para---2hF8X{font-size:11px;white-space:pre-wrap}.tip---y-RBM .dialogue---3CDPZ div{line-height:15px}.tip---y-RBM .tri---2mCwt{position:absolute;z-index:10;transform:translate(-50%, -180%)}", ""]);

// exports
exports.locals = {
	"tip": "tip---y-RBM",
	"dialogue": "dialogue---3CDPZ",
	"left": "left---NleWS",
	"para": "para---2hF8X",
	"tri": "tri---2mCwt"
};