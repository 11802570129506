exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".info-icon---3f6FH .isvg g{fill:#3477c7 !important}.info-icon---3f6FH.info-icon-active---3zGbd .info-icon-btn{background-color:#3477c7}.info-icon---3f6FH.info-icon-active---3zGbd .info-icon-btn .isvg g{fill:#fff !important}", ""]);

// exports
exports.locals = {
	"info-icon": "info-icon---3f6FH",
	"info-icon-active": "info-icon-active---3zGbd"
};