exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".header---1xVMn{color:#fff;padding:14px 40px;background-color:#3477c7;display:flex;position:relative;justify-content:space-between}.header---1xVMn .left---oYOSq{display:flex;flex-direction:row;overflow:hidden;min-width:125px}.header---1xVMn .left---oYOSq .logo---2NVZB{height:calc(2*100px/3);width:115px;min-width:115px;padding:0}.header---1xVMn .left---oYOSq .logo---2NVZB svg{transform:scale(0.65) translate(-25%, -25%)}.header---1xVMn .left---oYOSq .text---2zzZb{margin-left:10px;margin-top:3px;overflow:hidden}.header---1xVMn .left---oYOSq .text---2zzZb>*{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-size:14px}.header---1xVMn .left---oYOSq .text-light---1CBrj{font-weight:200;font-size:16px;display:block}.header---1xVMn .right---3VRsP{display:flex;flex-direction:row;justify-content:flex-end;overflow:hidden}.header---1xVMn .right---3VRsP .maths-az-btn---2op7o{margin:7px 20px 0;padding-left:15px;padding-top:2px;width:auto;min-width:165px;height:35px;display:flex;align-items:center;background-color:#00acee;border-radius:20px;color:#fff;text-decoration:none;border-top-right-radius:0}.header---1xVMn .right---3VRsP .maths-az-btn---2op7o>span{padding:0 5px 5px;white-space:nowrap}@media only screen and (max-width: 800px){.header---1xVMn .right---3VRsP .maths-az-btn---2op7o>span{margin-right:5px}}.header---1xVMn .right---3VRsP .maths-az-btn---2op7o .new-tab-icon---2eO4a{margin-right:5px;transform:translateY(-75%)}@media only screen and (max-width: 800px){.header---1xVMn .right---3VRsP .maths-az-btn---2op7o{min-width:75px}.header---1xVMn .right---3VRsP .maths-az-btn---2op7o .hidden-in-portrait---1mEdP{display:none}}.header---1xVMn .no-collapse---pjwgV{flex-shrink:0}", ""]);

// exports
exports.locals = {
	"header": "header---1xVMn",
	"left": "left---oYOSq",
	"logo": "logo---2NVZB",
	"text": "text---2zzZb",
	"text-light": "text-light---1CBrj",
	"right": "right---3VRsP",
	"maths-az-btn": "maths-az-btn---2op7o",
	"new-tab-icon": "new-tab-icon---2eO4a",
	"hidden-in-portrait": "hidden-in-portrait---1mEdP",
	"no-collapse": "no-collapse---pjwgV"
};