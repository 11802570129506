/* eslint-disable no-console */

import environment from '@3plearning/resource';
import { appName } from '../containers/app/app-config';

class BasicResources {
  static initialise(envName, credentials) {
    const env = environment(envName, appName);

    env.setConfiguration(window._env_);

    if (credentials.authToken) {
      env.authToken = credentials.authToken;
    } else if (credentials.username && credentials.password) {
      env.username = credentials.username;
      env.password = credentials.password;
    } else {
      // The caller should have provided a valid set of credentials, so
      // hopefully this will never happen.

      console.log('BasicResources.initialise() - to execute, we need either an authToken or' +
                ' a username/password pair.  The credentials object that was provided does not' +
                ' contain either.  So, expect things to fail badly :-(.');
    }

    return env;
  }
}

export default BasicResources;
