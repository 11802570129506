import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import '../../styles/core.scss';
import css from './modal.scss';
import { activateModal } from './modal-actions';

class Modal extends Component {
  static propTypes = {
    modals: PropTypes.array,
    modal: PropTypes.object,
    activateModal: PropTypes.func
  };

  canvasOnClick = () => {
    const modal = this.props.modal;
    if (modal && modal.canvasOnClick) {
      this.props.activateModal(false);
    }
  };

  // force focus to modal if active
  componentDidUpdate(prevProps) {
    const modal = this.props.modal;
    const prevModal = prevProps.modal;
    if (modal && prevModal && modal.activate && !prevModal.activate) {
      this.modalRef.focus();
    }
  }

  render() {
    const calcZIndex =
      (modalIndex, nestedIndex) => ({ zIndex: 1000 + (modalIndex * 2) + nestedIndex });
    return (
      this.props.modals.map((state, modalIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`modal-${modalIndex}`} className={css.component}>
          {state.activate && (
            <ReactCSSTransitionGroup
              transitionName="fade-in"
              transitionAppear
              transitionAppearTimeout={2000}
              transitionEnter={false}
              transitionLeave={false}
            >
              <div
                role="presentation"
                className={`${css.canvas} ${state.customStyle || ''}`}
                onClick={this.canvasOnClick}
                style={calcZIndex(modalIndex, 0)}
              />
            </ReactCSSTransitionGroup>
          )}
          {state.activate && (
            <ReactCSSTransitionGroup
              transitionName="modal-slide-down"
              transitionAppear
              transitionAppearTimeout={2000}
              transitionEnter={false}
              transitionLeave={false}
              className={css['modal-container']}
              style={calcZIndex(modalIndex, 1)}
            >
              <div
                className={css.modal}
                tabIndex="-1"
                ref={(el) => { this.modalRef = el; }}
                style={calcZIndex(modalIndex, 2)}
              >
                {state.content}
                <input
                  className={css['modal-anchor']}
                  onFocus={() => this.modalRef.focus()}
                />
              </div>
            </ReactCSSTransitionGroup>
          )}
        </div>
      ))
    );
  }
}

function mapStateToProps(state) {
  return {
    modals: state.modalReducer,
    modal: state.modalReducer.slice().pop()
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      activateModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
