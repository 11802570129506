import _ from 'lodash';
import { ERROR_ACTIVATED } from '../error-handling/error-handling-actions';

export const initialState = {
  status: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR_ACTIVATED: {
      const cloned = _.clone(state);
      cloned.status = action.status;
      return cloned;
    }

    default:
      return state;
  }
};
