import { constructModal } from '../modal/modal-actions';
import invalidCourseModalProperties from './invalid-course-error-content';

export const ERROR_ACTIVATED = 'ERROR_ACTIVATED';

export const activateError = status => ({
  type: ERROR_ACTIVATED,
  status
});

export const showInvalidCourseModal = () => (dispatch) => {
  dispatch(constructModal(invalidCourseModalProperties));
};
