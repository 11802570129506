exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".congratulations---1nD6P{display:flex;align-items:center;justify-content:center;width:100%;height:100%;border-radius:10px;padding:30px;position:relative}.congratulations---1nD6P .curtains---1x6M2{position:absolute;top:0;left:0;width:100%;height:100%}.congratulations---1nD6P .curtains---1x6M2 .top---2WT5y,.congratulations---1nD6P .curtains---1x6M2 .bottom---2WZId{position:absolute;border-top-right-radius:16px;border-style:solid;border-width:130px 220px;border-color:#fef #fef rgba(0,0,0,0) rgba(0,0,0,0)}.congratulations---1nD6P .curtains---1x6M2 .top---2WT5y::after,.congratulations---1nD6P .curtains---1x6M2 .bottom---2WZId::after{content:\"\";background-color:#fffaff;width:600px;height:17px;position:absolute;right:-300px;top:-45px;transform:rotate(30.5deg)}.congratulations---1nD6P .curtains---1x6M2 .top---2WT5y{right:-34px;top:-30px}.congratulations---1nD6P .curtains---1x6M2 .bottom---2WZId{bottom:-30px;left:-34px;transform:rotate(180deg)}.congratulations---1nD6P .content---3jYnj{display:inline-block;width:95%;text-align:center;z-index:1}.congratulations---1nD6P .toast---1QGcX strong{color:inherit;font-family:\"muli_blackregular\",arial,sans-serif}", ""]);

// exports
exports.locals = {
	"congratulations": "congratulations---1nD6P",
	"curtains": "curtains---1x6M2",
	"top": "top---2WT5y",
	"bottom": "bottom---2WZId",
	"content": "content---3jYnj",
	"toast": "toast---1QGcX"
};