import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValueFromUrl } from '../../helpers/utils';
import {
  fetchAssignmentDetails,
  setAssignmentId,
  enableSpinner,
  createAssignment,
  fetchAssignmentDetailsSuccess
} from './mle-actions';
import { getQuestionSuccess, updateQuestion } from '../activity/activity-actions';
import { LEARNOSITY_SPINNER, MAX_RESPONSES, NO_RESPONSE } from '../../helpers/constants';

// eslint-disable-next-line import/prefer-default-export
export const useAssignUpf = () => {
  const dispatch = useDispatch();
  const { assignmentIdReducer } = useSelector(state => state);

  useEffect(() => {
    const assignmentId = getValueFromUrl('assignmentId');
    const courseId = getValueFromUrl('courseId');
    const learningJourneyId = getValueFromUrl('learningJourneyId');
    const courseVersion = getValueFromUrl('courseVersion');
    const classroomId = getValueFromUrl('classroomId');
    const pageId = getValueFromUrl('pageId');
    const locale = getValueFromUrl('locale');
    const layoutVersion = getValueFromUrl('layoutVersion');
    const contentVersion = getValueFromUrl('contentVersion');
    if (assignmentId) {
      dispatch(setAssignmentId(assignmentId));
    } else if (pageId) {
      dispatch(fetchAssignmentDetailsSuccess({
        courseId: 0,
        courseVersionId: 0,
        standardId: 0,
        learningJourneyId: 0,
        stepCount: 5,
        stepsCompleted: 0,
        user: {}
      }));
      dispatch(getQuestionSuccess({
        streakAchieved: false,
        streak: Array(MAX_RESPONSES).fill(NO_RESPONSE),
        totalPoints: 0,
        needAttention: false,
        stepsCompleted: 0,
        nextQuestion: { pageId: +pageId, locale, layoutVersion, contentVersion }
      }));
    } else {
      dispatch(createAssignment({
        courseId,
        learningJourneyId,
        courseVersion,
        classroomId
      }));
    }
  }, []);

  useEffect(() => {
    const locale = getValueFromUrl('locale');
    if (assignmentIdReducer) {
      dispatch(fetchAssignmentDetails(assignmentIdReducer, locale));
      dispatch(updateQuestion({ assignmentId: assignmentIdReducer, locale }));
      dispatch(enableSpinner(LEARNOSITY_SPINNER));
    }
  }, [assignmentIdReducer]);
};
