import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import { constructModal } from '../modal/modal-actions';
import { ModalGeneric } from '../../components/modal-content';
import { activateError } from './error-handling-actions';
import loginImage from '../../assets/images/error-boy.gif';
import css from './error-handling.scss';

const ErrorHandling = () => {
  const dispatch = useDispatch();
  const status = useSelector(state => state.errorHandlingReducer.status);
  const [errorWindowLoaded, setErrorWindowLoaded] = useState(false);
  const { i18n } = useLocalisation();

  const handleSignIn = () => {
    window.location.href = window._env_.StudentLoginPage;
  };

  const modalData = {
    header: i18n._(t`Something went wrong`),
    subHeader: (
      <Fragment>
        <img className={css['error-boy']} src={loginImage} alt={i18n._(t`Error boy`)} />
        {i18n._(t`Please try logging in again`)}
      </Fragment>
    ),
    buttons: [
      {
        text: i18n._(t`Sign in`),
        callBack: handleSignIn,
        size: 'large'
      }
    ]
  };

  useEffect(() => {
    if (status && !errorWindowLoaded) {
      setErrorWindowLoaded(true);
      dispatch(constructModal({
        activate: true,
        content: <ModalGeneric
          modalData={modalData}
        />,
        canvasOnClick: false
      }));
      // Reset the reducer in case the next error is the same status
      dispatch(activateError(null));
    }
  }, [status]);

  return false;
};

export default ErrorHandling;
