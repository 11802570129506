import { useDispatch, useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import React, { useEffect } from 'react';
import { useLocalisation } from '../../../helpers/i18n/use-localisation';
import { activateModal, constructModal } from '../../modal/modal-actions';
import { ModalGeneric } from '../../../components/modal-content';


export const useBadStreak = () => {
  const dispatch = useDispatch();
  const { i18n } = useLocalisation();
  const { needAttention, isInitialQuestion } = useSelector(state => state.activityReducer);

  const showBadStreakModal = () => {
    const modalData = {
      subHeader: i18n._(t`Looks like you might need some extra help with this topic!`),
      paragraph: `${i18n._(t`Your teacher may come and help you.`)}\n
      ${i18n._(t`You can still keep going with this quest in the meantime if you like.`)}`,
      buttons: [
        {
          text: i18n._(t`Ok`),
          callBack: () => dispatch(activateModal(false)),
          size: 'medium'
        }
      ]
    };

    dispatch(constructModal({
      activate: true,
      content: <ModalGeneric modalData={modalData} />,
      canvasOnClick: false
    }));
  };

  useEffect(() => {
    if (needAttention && !isInitialQuestion) {
      showBadStreakModal();
    }
  }, [needAttention]);
};
