exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".gems-container---btGLx{margin:30px;display:flex;justify-content:center}.gems-container---btGLx .gem---y9Ndm{width:92px;height:80px}.gems-container---btGLx .gem---y9Ndm:nth-child(even){transform:rotate(8deg)}.gems-container---btGLx .gem---y9Ndm:nth-child(odd){transform:rotate(-8deg)}.gems-container---btGLx .gem---y9Ndm:only-child{transform:none}", ""]);

// exports
exports.locals = {
	"gems-container": "gems-container---btGLx",
	"gem": "gem---y9Ndm"
};