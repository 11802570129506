import Resources from '../../helpers/resources';
import { activateError } from '../error-handling/error-handling-actions';
import { LANDING_SPINNER, LOCALE_DEFAULT, ASSIGNMENT_ID_SPINNER } from '../../helpers/constants';

export const GET_ASSIGNMENT_DETAILS_SUCCESS = 'GET_ASSIGNMENT_DETAILS_SUCCESS';
export const COMPONENT_ENABLED_SPINNER = 'COMPONENT_ENABLED_SPINNER';
export const COMPONENT_DISABLED_SPINNER = 'COMPONENT_DISABLED_SPINNER';
export const ASSIGNMENT_ID_SET = 'ASSIGNMENT_ID_SET';

export const fetchAssignmentDetailsSuccess = payload => ({
  type: GET_ASSIGNMENT_DETAILS_SUCCESS,
  payload
});

export const enableSpinner = item => ({
  type: COMPONENT_ENABLED_SPINNER,
  item
});

export const disableSpinner = item => ({
  type: COMPONENT_DISABLED_SPINNER,
  item
});

export const setAssignmentId = id => ({
  type: ASSIGNMENT_ID_SET,
  id
});

export const fetchAssignmentDetails = (assignmentId, locale = LOCALE_DEFAULT) => async (dispatch) => {
  dispatch(enableSpinner(LANDING_SPINNER));
  try {
    const assignmentDetailsResponse = await Resources.getAssignmentDetails
      .post()
      .withBody({ assignmentId, productId: window._env_.ProductId, locale })
      .send();

    const { status, data } = assignmentDetailsResponse;

    if (status === 200 || status === 204) {
      dispatch(fetchAssignmentDetailsSuccess(data));
    }
    dispatch(disableSpinner(LANDING_SPINNER));
  } catch (e) {
    dispatch(activateError('network error'));
    dispatch(disableSpinner(LANDING_SPINNER));
  }
};

export const createAssignment = ({
  courseId,
  learningJourneyId,
  courseVersion,
  classroomId
}) => async (dispatch) => {
  dispatch(enableSpinner(ASSIGNMENT_ID_SPINNER));
  try {
    const payload = await Resources.createAssignment
      .post()
      .withBody({
        courseId,
        learningJourneyId,
        courseVersion,
        classroomId,
        productId: window._env_.ProductId
      })
      .send();

    const { status, data } = payload;

    if (status === 200 || status === 204) {
      dispatch(setAssignmentId(data.assignmentId));
    }
    dispatch(disableSpinner(ASSIGNMENT_ID_SPINNER));
  } catch (e) {
    dispatch(activateError('network error'));
    dispatch(disableSpinner(ASSIGNMENT_ID_SPINNER));
  }
};
