exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".error-boy---1F9Hp{height:200px;display:block;margin:0 auto}", ""]);

// exports
exports.locals = {
	"error-boy": "error-boy---1F9Hp"
};