const gemsOrigin = {
  crown1: {
    gem1: {
      opacity: 1,
      width: '27%',
      height: '27%',
      left: '37.8%',
      top: '6.5%',
      transform: 'scale(1)'
    }
  },
  crown2: {
    gem1: {
      opacity: 1,
      width: '28%',
      height: '28%',
      left: '17.5%',
      top: '42.5%',
      transform: 'scale(1)'
    },
    gem2: {
      opacity: 1,
      width: '28%',
      height: '28%',
      left: '54%',
      top: '42.5%',
      transform: 'scale(1)'
    }
  },
  crown3: {
    gem1: {
      opacity: 1,
      width: '26%',
      height: '26%',
      left: '12%',
      top: '28%',
      transform: 'scale(1)'
    },
    gem2: {
      opacity: 1,
      width: '26%',
      height: '26%',
      left: '37%',
      top: '28.5%',
      transform: 'scale(1)'
    },
    gem3: {
      opacity: 1,
      width: '26%',
      height: '26%',
      left: '61.7%',
      top: '28%',
      transform: 'scale(1)'
    }
  },
  crown4: {
    gem1: {
      opacity: 1,
      width: '27%',
      height: '27%',
      left: '-0.5%',
      top: '62.5%',
      transform: 'scale(1)'
    },
    gem2: {
      opacity: 1,
      width: '27%',
      height: '27%',
      left: '13%',
      top: '35%',
      transform: 'scale(1)'
    },
    gem3: {
      opacity: 1,
      width: '27%',
      height: '27%',
      left: '60%',
      top: '35%',
      transform: 'scale(1)'
    },
    gem4: {
      opacity: 1,
      width: '27%',
      height: '27%',
      left: '74%',
      top: '62.5%',
      transform: 'scale(1)'
    }
  },
  crown5: {
    gem1: {
      opacity: 1,
      width: '27%',
      height: '27%',
      left: '10.5%',
      top: '58%',
      transform: 'scale(1)'
    },
    gem2: {
      opacity: 1,
      width: '27%',
      height: '27%',
      left: '4%',
      top: '13.5%',
      transform: 'scale(1)'
    },
    gem3: {
      opacity: 1,
      width: '27%',
      height: '27%',
      left: '37%',
      top: '2%',
      transform: 'scale(1)'
    },
    gem4: {
      opacity: 1,
      width: '27%',
      height: '27%',
      left: '68.5%',
      top: '13.5%',
      transform: 'scale(1)'
    },
    gem5: {
      opacity: 1,
      width: '27%',
      height: '27%',
      left: '65.5%',
      top: '58%',
      transform: 'scale(1)'
    }
  }
};

export default gemsOrigin;
