import { MAX_RESPONSES, NO_RESPONSE } from '../../helpers/constants';

const WRONG_RESPONSES_ALLOWED = 2;

const stripUnattemptedResponses = responses => responses.filter(response => response !== NO_RESPONSE);

const getRetainingResponses = (responses, isFetching) => {
  const attemptedResponses = stripUnattemptedResponses(responses);
  const isLatestIncorrect = !attemptedResponses[attemptedResponses.length - 1];
  const incorrectCount = attemptedResponses.reduce((count, response) => count - response, attemptedResponses.length);
  const incorrectDisplayed = isFetching && isLatestIncorrect ? WRONG_RESPONSES_ALLOWED + 1 : WRONG_RESPONSES_ALLOWED;
  if (incorrectCount > incorrectDisplayed) {
    let extraIncorrects = 0;
    let indexForNewList = 0;
    while (extraIncorrects < incorrectCount - incorrectDisplayed) {
      if (!attemptedResponses[indexForNewList]) extraIncorrects += 1;
      indexForNewList += 1;
    }
    return attemptedResponses.slice(indexForNewList);
  }

  return attemptedResponses;
};

const getDisplayResponsesFromAttemptedResponses = (responses, isFetching) => {
  const retainingResponses = getRetainingResponses(responses, isFetching);
  while (retainingResponses.length < MAX_RESPONSES) {
    retainingResponses.push(NO_RESPONSE);
  }
  return retainingResponses;
};

export const updateStreakWithResponse = (previousStreak, response) => {
  previousStreak.shift();
  previousStreak.push(response);
  return previousStreak;
};

export default getDisplayResponsesFromAttemptedResponses;
