import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ConserveParameters from '@3plearning/routing-conserve-parameters';
import { I18nProvider } from '@lingui/react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { defaultTheme } from '@3plearning/question-components-library';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import '../../styles/core.scss';
import BasicApp from './basic-app';
import Mle from '../mle/mle';
import Intercept3pResourcesError from '../../helpers/intercept-3p-rescources-error';
import { mleTheme } from '../../helpers/mle-theme';

const history = createBrowserHistory();
const queryClient = new QueryClient();

ConserveParameters(['username', 'password', 'userToken']);

export default class EntryApp extends BasicApp {
  render(store, i18n) {
    // TODO: 4. DEFINE THE TOP-LEVEL ROUTES FOR YOUR APPLICATION'S COMPONENTS HERE

    // When executing on demo, QA, or production, the host app will inject a property
    // called pppAppConfig into the global window variable.  This will provide the
    // base name of the app.  Most of the time that will be an empty string, but in
    // case it is not, we will extract it and pass it to the router, below.

    const appConfig = window.pppAppConfig;
    const BASE_NAME = appConfig ? appConfig.BaseName : '';
    Intercept3pResourcesError.addResourceResponseErrorInterceptor(store.dispatch);

    // NOTE: We have used a "render" attribute, rather than "component" to specify what
    //       should be rendered.  This allows us to pass options to the component. You
    //       can obviously simplify things if your components don't need any.

    return (
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={extendTheme(mleTheme, defaultTheme)}>
          <I18nProvider i18n={i18n} >
            <Provider store={store}>
              <Router history={history} basename={BASE_NAME}>
                <Switch>
                  <Route path="/" render={() => <Mle options={this.options} />} />
                </Switch>
              </Router>
            </Provider>
          </I18nProvider>
        </ChakraProvider>
      </QueryClientProvider>
    );
  }
}

window.EntryApp = EntryApp;
