exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".activity---1THe9{width:calc(100% - 275px);margin:0 5px 0 40px;background-color:#fff;border-radius:16px;position:relative}.activity---1THe9.completed---2FdXo{background-color:#fffaff}.activity---1THe9 .inner-scroll---J442S{overflow-y:auto;padding:30px 34px;width:100%;height:100%;position:absolute}.activity---1THe9 .inner-scroll---J442S.completed---2FdXo{overflow:hidden}.activity---1THe9 .tri---2dQKL{position:absolute;right:0;bottom:0;transform:translate(0, 100%)}@media only screen and (max-width: 800px){.activity---1THe9{width:100%;margin:0 40px}}.activity---1THe9 .keyboard-btn---wPg7D{position:absolute;bottom:25px;border-radius:20px;padding:0;background:#fd7849;transform:translateX(-50%);height:40px;width:40px;z-index:1;display:flex;justify-content:center;align-items:center}.activity---1THe9 .keyboard-btn---wPg7D.active---1Lnfc{background:#3477c7}.activity---1THe9 .keyboard-btn---wPg7D svg{width:22px;height:22px;vertical-align:middle}.activity---1THe9 .tts---2c7kl{left:-20px;top:12px}.activity---1THe9 .tts---2c7kl button{display:flex;justify-content:center;align-items:center}.activity---1THe9 .tts---2c7kl button svg{display:unset}", ""]);

// exports
exports.locals = {
	"activity": "activity---1THe9",
	"completed": "completed---2FdXo",
	"inner-scroll": "inner-scroll---J442S",
	"tri": "tri---2dQKL",
	"keyboard-btn": "keyboard-btn---wPg7D",
	"active": "active---1Lnfc",
	"tts": "tts---2c7kl"
};