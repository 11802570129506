exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".step-completed-banner---1Ye4K{width:156px;height:204px;overflow:hidden}.step-completed-banner---1Ye4K .banner---1tTI4{width:100%;height:100%;overflow:hidden;position:relative;z-index:0}.step-completed-banner---1Ye4K .banner---1tTI4 .level-up---RdNWr{font-family:\"muli_blackregular\";color:#fd7849;font-size:228%;z-index:20;width:100%;text-align:center;text-transform:uppercase;transform:skew(0deg, -6deg);line-height:98%;text-shadow:0 5px 0 rgba(0,0,0,.5);position:absolute;top:10%;padding-left:6%}.step-completed-banner---1Ye4K .banner---1tTI4 .msg---Fn8XE{color:#fff;font-weight:bold;text-align:center;position:absolute;top:55%;z-index:10;font-size:78%;width:100%}.step-completed-banner---1Ye4K .banner---1tTI4 img{width:100%;height:100%;position:absolute;z-index:0}@media only screen and (max-width: 800px){.step-completed-banner---1Ye4K{display:none}}", ""]);

// exports
exports.locals = {
	"step-completed-banner": "step-completed-banner---1Ye4K",
	"banner": "banner---1tTI4",
	"level-up": "level-up---RdNWr",
	"msg": "msg---Fn8XE"
};