import Resources from '../../helpers/resources';
import { getValueFromUrl } from '../../helpers/utils';

export const isMarkActivityDisabled = ({ isLoading, userResponse }) => (
  isLoading || !userResponse || !userResponse.isAttempted
);

export const getTextSpeechConfig = () => ({
  authToken: Resources.env.authToken,
  serviceHostUrl: window._env_.TextToSpeechHostUrl,
  dataUrl: window._env_.TextToSpeechDataUrl,
  locale: getValueFromUrl('locale') || 'en-AU'
});

export const getDifferenceInSeconds = ({ startDate, endDate }) =>
  Math.round((endDate.getTime() - startDate.getTime()) / 1000);

export default isMarkActivityDisabled;
