exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".info-feedback---2azim{position:relative}.info-feedback---2azim .btn-info---VYpQs{width:40px;height:40px}.info-feedback---2azim .btn-info---VYpQs span,.info-feedback---2azim .btn-info---VYpQs svg{width:100%;height:100%}.info-feedback---2azim .btn-info---VYpQs.btn-info-active---13GrD .info-icon-btn .isvg path{fill:#fd7849 !important}.info-feedback---2azim .user-feedback---1j4sO{position:absolute;left:39px;top:50%;transition:left .5s}.info-feedback---2azim .user-feedback-active---11f-B{left:58px}.info-feedback---2azim .feedback-comment{transform:translateX(20%)}", ""]);

// exports
exports.locals = {
	"info-feedback": "info-feedback---2azim",
	"btn-info": "btn-info---VYpQs",
	"btn-info-active": "btn-info-active---13GrD",
	"user-feedback": "user-feedback---1j4sO",
	"user-feedback-active": "user-feedback-active---11f-B"
};