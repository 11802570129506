export const ANSWER_UPDATED = 'ANSWER_UPDATED';
export const ANSWER_VALIDATED = 'ANSWER_VALIDATED';
export const IS_NEW_CONTENT_SET = 'IS_NEW_CONTENT_SET';
export const CONQUEST_STATE_RESET = 'CONQUEST_STATE_RESET';
export const KEYPAD_REF_SET = 'KEYPAD_CONFIG_SET';
export const KEYPAD_ACTIVATED = 'KEYPAD_ACTIVATED';

export const updateAnswer = payload => ({
  type: ANSWER_UPDATED,
  payload
});

export const validateAnswer = (validation) => {
  return {
    type: ANSWER_VALIDATED,
    validation
  };
};

export const setIsNewContent = (isNewContent) => {
  return {
    type: IS_NEW_CONTENT_SET,
    isNewContent
  };
};

export const resetConquestState = () => {
  return {
    type: CONQUEST_STATE_RESET
  };
};

export const setKeypadRef = (ref) => {
  return {
    type: KEYPAD_REF_SET,
    ref
  };
};

export const activateKeypad = (isEnabled) => {
  return {
    type: KEYPAD_ACTIVATED,
    isEnabled
  };
};
