import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@lingui/macro';
import classNames from 'classnames';
import iconQuestion from '../../assets/images/icon_questionmark.svg';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import css from './info.scss';
import InfoIcon from '../../components/icon-info/info-icon';
import { setActivityActive } from '../activity/activity-actions';

const Info = () => {
  const dispatch = useDispatch();
  const [showTip, setShowTip] = useState(false);
  const { i18n } = useLocalisation();

  const infoIconData = {
    icon: iconQuestion,
    paragraph: `${i18n._(t`When you finish a question, click on the Check my answer button to mark your answer.
    You will then see the Next button to move to the next question.`)}\n\n${i18n._(t`To move up a level, 
    you will need to get a streak - either 5 correct answers in a row or 6 out of 8 correct`)}`,
    showTip,
    onClick: e => setShowTip(e ? !showTip : false)
  };

  useEffect(() => {
    dispatch(setActivityActive(!showTip));
  }, [showTip]);

  return (
    <InfoIcon
      className={classNames(css['info-icon'], { [css['info-icon-active']]: showTip })}
      infoIconData={infoIconData}
    />
  );
};


export default Info;
