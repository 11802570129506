import React, { useRef, useEffect } from 'react';
import { Box, Img, Portal } from '@chakra-ui/react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { useKeypad } from './hooks/use-keypad';
import dragIcon from '../../assets/images/Move.svg';
import css from './keypad-container.scss';
import { setKeypadRef } from './conquest.actions';

export const KeypadContainer = () => {
  const { isKeypadActivated } = useSelector(state => state.conquestReducer);
  const dispatch = useDispatch();
  const keypadRef = useRef(null);
  const { handleDrag, checkInjectedKeypad, showKeypad } = useKeypad();

  useEffect(() => {
    if (keypadRef.current) {
      checkInjectedKeypad(keypadRef);
      dispatch(setKeypadRef(keypadRef.current));
    }
  }, [keypadRef.current]);

  return (
    <Portal>
      <Draggable
        bounds="body"
        onDrag={evt => handleDrag(evt)}
        onStart={evt => handleDrag(evt)}
        onStop={evt => handleDrag(evt)}
      >
        <Box
          className={css['keypad-container']}
          zIndex={showKeypad && isKeypadActivated ? 1000 : -1}
          ref={keypadRef}
        >
          <Box className={css['keypad-container-header']}>
            <Img
              className={css['drag-icon']}
              src={dragIcon}
            />
          </Box>
        </Box>
      </Draggable>
    </Portal>
  );
};
