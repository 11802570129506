exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".button---2wN9Z{border-radius:30px;border:none;color:#fff;font-weight:bold;outline:none}.button---2wN9Z.primary---1QxvV{background-color:#3477c7}@media(hover: hover){.button---2wN9Z.primary---1QxvV:hover{background-color:#fd7849}}.button---2wN9Z.primary---1QxvV:disabled{background-color:#3477c7;opacity:.5;pointer-events:none}.button---2wN9Z.secondary---3nzXr{background-color:#fd7849}@media(hover: hover){.button---2wN9Z.secondary---3nzXr:hover{background-color:#3477c7}}.button---2wN9Z.secondary---3nzXr:disabled{background-color:#fd7849;opacity:.5;pointer-events:none}.button---2wN9Z.primary---1QxvV:active,.button---2wN9Z.secondary---3nzXr:active{background-color:#b2e1e3}.button---2wN9Z.large---2k-YM{padding:0 30px;height:50px;font-size:16px;min-width:120px;font-weight:500}.button---2wN9Z.medium---ExLs7{padding:0 25px;height:32px;font-size:13px;font-weight:unset}.button---2wN9Z.small---3X-K8{padding:0 15px;height:30px;font-size:11px}", ""]);

// exports
exports.locals = {
	"button": "button---2wN9Z",
	"primary": "primary---1QxvV",
	"secondary": "secondary---3nzXr",
	"large": "large---2k-YM",
	"medium": "medium---ExLs7",
	"small": "small---3X-K8"
};