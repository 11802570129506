import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './tip.scss';
import Triangle from '../triangle/triangle';

const Tip = ({ type, paragraph, className }) => (
  <div className={classNames(className, css.tip)}>
    <div className={classNames(css.dialogue, css[type])}>
      <div className={css.para}>{paragraph}</div>
    </div>
    <Triangle
      className={css.tri}
      leftPx={'16'}
      rightPx={'16'}
      topPx={'22'}
      color={'#b2e1e3'}
    />
  </div >
);

Tip.propTypes = {
  type: PropTypes.string,
  paragraph: PropTypes.node,
  className: PropTypes.string
};

export default Tip;
