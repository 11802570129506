import React from 'react';
import { useSelector } from 'react-redux';
import css from './student-progress.scss';
import CrownContainer from '../../components/crown/crown-container/crown-container';
import ProgressTower from './progress-tower';

const StudentProgress = () => {
  const {
    mleReducer,
    activityReducer,
    studentProgressReducer
  } = useSelector(state => state);

  const { totalPoints = 0, streak = [], stepsCompleted, isFetching } = activityReducer;
  const { stepCount } = mleReducer || {};

  return (
    <div className={css['student-progress']}>
      <CrownContainer />
      {
        stepCount && stepsCompleted !== null &&
        <ProgressTower
          points={totalPoints}
          streak={streak}
          narrowToggle={studentProgressReducer.narrowToggle}
          isFetching={isFetching}
        />
      }
    </div>
  );
};

export default StudentProgress;
