exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".component---2FjYK{position:absolute;overflow:hidden;max-width:100%;z-index:1010}.component---2FjYK .canvas---jUjMA{position:fixed;background-color:rgba(0,0,0,.5);top:0;left:0;height:100vh;width:100vw;z-index:1000}.component---2FjYK .modal-container---2Kouc{display:flex;top:0;left:0;height:100vh;width:100vw;z-index:1001;align-items:center;justify-content:center}.component---2FjYK .modal---23JL2{position:fixed;border-radius:20px;background-color:#fff;z-index:1002;max-height:calc(100vh - 40px);overflow:hidden;outline:none;left:50%;top:50%;transform:translate(-50%, -50%)}.component---2FjYK .modal-anchor---2-dNP{opacity:0;width:0;height:0;border:none}.fade-in-appear{opacity:.01}.fade-in-appear.fade-in-appear-active{opacity:1;transition:opacity 300ms ease-in}.modal-slide-down-appear{opacity:.01;margin-top:-200px !important}.modal-slide-down-appear.modal-slide-down-appear-active{opacity:1;margin-top:0 !important;transition:opacity 200ms ease-in,margin-top 200ms ease-in}", ""]);

// exports
exports.locals = {
	"component": "component---2FjYK",
	"canvas": "canvas---jUjMA",
	"modal-container": "modal-container---2Kouc",
	"modal": "modal---23JL2",
	"modal-anchor": "modal-anchor---2-dNP"
};