import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import logo from '../../assets/images/mathletics-logo.svg';
import css from './header.scss';
import { BtnIcon } from '../../components/buttons';
import Profile from '../profile/profile';
import mathsAZIcon from '../../assets/images/MAZ_icon.svg';
import newTabIcon from '../../assets/images/MAZ_newtab_icon.svg';

const Header = ({
  journey = 'Learning journey',
  standard = 'Standard',
  firstName = '',
  mathsAZUrl
}) => (
  <div className={css.header}>
    <div className={css.left}>
      <BtnIcon icon={logo} className={css.logo} />
      <div className={css.text}>
        <p>{standard}</p>
        <span className={css['text-light']}>{journey}</span>
      </div>
    </div>
    <div className={classNames(css.right, { [css['no-collapse']]: firstName.length <= 10 })}>
      {mathsAZUrl && <a
        className={css['maths-az-btn']}
        href={mathsAZUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={mathsAZIcon} alt="Maths A-Z Icon" className={css['hidden-in-portrait']} />
        <span><span className={css['hidden-in-portrait']}>Maths </span>A-Z</span>
        <img src={newTabIcon} alt="open in new tab" className={css['new-tab-icon']} />
      </a>}
      <Profile firstName={firstName} />
    </div>
  </div>
);

Header.propTypes = {
  journey: PropTypes.string,
  standard: PropTypes.string,
  firstName: PropTypes.string,
  mathsAZUrl: PropTypes.string
};

export default Header;
