import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Tween } from 'react-gsap';
import merit from '../../../assets/images/hex_coin.svg';
import gemWhite from '../../../assets/images/gem-white.svg';
import css from './gem.scss';
import Flare from '../flare/flare';

const Gem = ({
  anim
}) => {
  const [completeAnim, setCompleteAnim] = useState(false);

  return (
    <div
      className={css.gem}
    >
      {
        anim ? (
          <Fragment>
            <Flare className={css.flare1} />
            <Tween
              from={{
                opacity: 0,
                left: '-50%'
              }}
              duration={0.6}
              delay={1.5}
            >
              <div className={css.flare2}>
                <Flare />
              </div>
            </Tween>
            <Tween
              from={{
                clipPath: 'polygon(-10% -5%, 110% -35%, 110% -15%, -10% 15%)',
                webkitClipPath: 'polygon(-10% -5%, 110% -35%, 110% -15%, -10% 15%)'
              }}
              to={{
                clipPath: 'polygon(-10% 130%, 110% 100%, 110% 120%, -10% 150%)',
                webkitClipPath: 'polygon(-10% 130%, 110% 100%, 110% 120%, -10% 150%)'
              }}
              duration={2}
              delay={1.2}
              onComplete={() => setCompleteAnim(true)}
            >
              <img
                style={completeAnim ? { display: 'none' } : {}}
                src={gemWhite}
                alt="gem"
                className={css['gem-white']}
              />
            </Tween>
            <Tween
              from={{
                filter: 'blur(4px)'
              }}
              to={{
                filter: 'blur(0px)'
              }}
              duration={2}
              delay={1.2}
            >
              <img
                src={merit}
                alt="merit"
                className={css['gem-bkgd']}
              />
            </Tween>
          </Fragment>
        ) : (
          <img
            src={merit}
            alt="merit"
            className={css['gem-bkgd']}
          />
        )
      }
    </div>
  );
};

Gem.propTypes = {
  anim: PropTypes.bool
};

export default Gem;
