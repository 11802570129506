import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Tween, SplitWords } from 'react-gsap';
import classNames from 'classnames';
import { t, Trans } from '@lingui/macro';
import { useLocalisation } from '../../../helpers/i18n/use-localisation';
import css from './step-completed-banner.scss';
import bkgd from '../../../assets/images/step-completed-banner.svg';

const StepCompletedBanner = ({ className }) => {
  const { streak, streakAchieved, stepsCompleted } = useSelector(state => state.activityReducer);
  const { stepCount } = useSelector(state => state.mleReducer);
  const { i18n } = useLocalisation();
  const [banner, setBanner] = useState({
    show: false,
    msg: <Fragment />
  });

  const streakMessage = () => {
    let counter = 0;
    streak.forEach((i) => {
      if (i !== -1) {
        counter += i;
      }
    });

    return counter === 5 ? (
      <div className={css.msg}>
        <Trans>
          5 correct in a row<br />
          You earned a gem!
        </Trans>
      </div>
    ) : (
      <div className={css.msg}>
        <Trans>
          6 out of 8 correct<br />
          You earned a gem!
        </Trans>
      </div>
    );
  };

  const levelUpText = () => i18n._(t`Level up`);

  useEffect(() => {
    if (streakAchieved && stepCount !== stepsCompleted) {
      setBanner({
        show: true,
        msg: streakMessage()
      });
    } else {
      setBanner({
        show: false,
        msg: <Fragment />
      });
    }
  }, [stepsCompleted]);

  return banner.show && (
    <div
      className={classNames(css['step-completed-banner'], className)}
    >
      <Tween
        to={{ y: '-100%' }}
        duration={0.5}
        delay={6}
        ease="Sine.easeOut"
        onComplete={() => setBanner({
          show: false,
          msg: <Fragment />
        })}
      >
        <div className={css.banner}>
          <div
            className={css['level-up']}
          >
            <Tween
              staggerFrom={{ y: '60%', opacity: 0 }}
              stagger={0.2}
              duration={0.3}
              delay={0.5}
              ease="Sine.easeOut"
            >
              <SplitWords>
                <div
                  style={{
                    display: 'inline-block'
                  }}
                >
                  {levelUpText()}
                </div>
              </SplitWords>
            </Tween>
          </div>
          <Tween
            from={{
              top: '65%',
              opacity: 0
            }}
            duration={0.5}
            delay={1.8}
            ease="Sine.easeOut"
          >
            {banner.msg}
          </Tween>
          <Tween
            from={{
              top: '-100%'
            }}
            duration={0.8}
            ease="Sine.easeOut"
          >
            <img src={bkgd} alt="background-banner" />
          </Tween>
        </div>
      </Tween>
    </div>
  );
};

StepCompletedBanner.propTypes = {
  className: PropTypes.string
};

export default StepCompletedBanner;
