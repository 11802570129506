/* global MutationObserver */

import { useState } from 'react';

export const useKeypad = () => {
  const [showKeypad, setShowKeypad] = useState(false);

  const handleDrag = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
  };

  const checkInjectedKeypad = (keypadRef) => {
    const mutation = new MutationObserver((entries) => {
      if (entries[entries.length - 1].addedNodes.length > 0) {
        setShowKeypad(true);
      } else {
        setShowKeypad(false);
      }
    });

    mutation.observe(keypadRef.current, { childList: true });
  };

  return { handleDrag, checkInjectedKeypad, showKeypad };
};
