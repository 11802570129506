import { CORRECT_RESPONSE, INCORRECT_RESPONSE, LOCALE_DEFAULT } from '../../helpers/constants';
import Resources from '../../helpers/resources';
import { activateError } from '../error-handling/error-handling-actions';
import { setDucsInstanceId } from '../ducs/ducs-actions';
import { getLearnosityResponse } from '../../helpers/utils';

export const GET_QUESTION_REQUESTED = 'GET_QUESTION_REQUESTED';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_ERROR = 'GET_QUESTION_ERROR';

export const SET_ACTIVITY_MARKED = 'SET_ACTIVITY_MARKED';
export const SET_ACTIVITY_RESPONSE = 'SET_ACTIVITY_RESPONSE';
export const SET_ACTIVITY_NEXT = 'SET_ACTIVITY_NEXT';
export const SET_ACTIVITY_LOADED = 'SET_ACTIVITY_LOADED';

export const SET_ACTIVITY_ACTIVE = 'SET_ACTIVITY_ACTIVE';
export const RESET_ACTIVITY = 'RESET_ACTIVITY';

export const getQuestionRequested = () => ({
  type: GET_QUESTION_REQUESTED
});

export const getQuestionSuccess = payload => ({
  type: GET_QUESTION_SUCCESS,
  payload
});

export const getQuestionError = () => ({
  type: GET_QUESTION_ERROR
});

export const updateQuestion = ({
  assignmentId,
  questionId,
  locale = LOCALE_DEFAULT,
  userResponse,
  timeToAnswerSec = 0
}) => async (dispatch) => {
  if (!assignmentId) {
    return;
  }
  try {
    dispatch(getQuestionRequested());
    let requestData = { assignmentId, locale, productId: window._env_.ProductId };
    let requestEndPoint = Resources.getQuestion;
    if (questionId) {
      requestData = {
        ...requestData,
        questionId,
        isCorrect: userResponse.isCorrect,
        timeToAnswerSec,
        responseData: JSON.stringify(getLearnosityResponse(userResponse))
      };
      requestEndPoint = Resources.saveQuestionResult;
    }
    const { status, data } = await requestEndPoint
      .post()
      .withBody(requestData)
      .send();
    if (status === 200 || status === 204) {
      dispatch(getQuestionSuccess(data));
    } else {
      dispatch(getQuestionError());
    }
  } catch (error) {
    dispatch(activateError(500));
    dispatch(getQuestionError());
  }
};

export const markActivity = userResponse => ({
  type: SET_ACTIVITY_MARKED,
  response: userResponse.isCorrect ? CORRECT_RESPONSE : INCORRECT_RESPONSE
});

export const setActivityResponse = userResponse => ({
  type: SET_ACTIVITY_RESPONSE,
  userResponse
});

export const nextActivity = () => ({
  type: SET_ACTIVITY_NEXT
});

export const activityLoaded = () => ({
  type: SET_ACTIVITY_LOADED
});

export const setActivityActive = status => ({
  type: SET_ACTIVITY_ACTIVE,
  status
});

export const resetActivity = () => ({
  type: RESET_ACTIVITY
});

export const clearActivity = () => (dispatch) => {
  dispatch(resetActivity());
  dispatch(setDucsInstanceId(null));
};
