import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import css from './points.scss';

const Points = ({ value }) => (
  <div className={css.points}>
    <h1>
      {value}
    </h1>
    <h5 className={css.label}><Trans>Points earned</Trans></h5>
  </div>
);

Points.propTypes = {
  value: PropTypes.number
};

export default Points;
