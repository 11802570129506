export const GET_DUCS_CONFIG_REQUESTED = 'GET_DUCS_CONFIG_REQUESTED';
export const GET_DUCS_CONFIG_SUCCESS = 'GET_DUCS_CONFIG_SUCCESS';
export const GET_DUCS_CONFIG_ERROR = 'GET_DUCS_CONFIG_ERROR';

export const SET_DUCS_INSTANCE_ID = 'SET_DUCS_INSTANCE_ID';

export const getDucsConfigRequested = () => ({
  type: GET_DUCS_CONFIG_REQUESTED
});
export const getDucsConfigSuccess = payload => ({
  type: GET_DUCS_CONFIG_SUCCESS,
  ...payload
});
export const getDucsConfigError = () => ({
  type: GET_DUCS_CONFIG_ERROR
});
export const getDucsConfig = () => async (dispatch) => {
  dispatch(getDucsConfigSuccess({
    url: window._env_.DUCSLibraryUrl,
    config: JSON.parse(window._env_.DUCSConfiguration)
  }));
};

export const setDucsInstanceId = instanceId => ({
  type: SET_DUCS_INSTANCE_ID,
  instanceId
});
