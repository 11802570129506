import {
  GET_QUESTION_REQUESTED,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_ERROR,
  SET_ACTIVITY_RESPONSE,
  SET_ACTIVITY_MARKED,
  SET_ACTIVITY_NEXT,
  SET_ACTIVITY_LOADED,
  SET_ACTIVITY_ACTIVE,
  RESET_ACTIVITY
} from './activity-actions';

import { updateStreakWithResponse } from '../student-progress/student-progress-utils';

const initialState = {
  isFetching: false,
  isLoading: true,
  isMarked: false,
  questions: [],
  userResponse: null,
  loadNextQuestion: false,
  isInitialQuestion: true,
  isActivityActive: true,
  startDate: null
};

const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTION_REQUESTED:
      return {
        ...state,
        isFetching: true
      };
    case GET_QUESTION_SUCCESS: {
      const { nextQuestion, ...rest } = action.payload;
      return {
        ...state,
        isFetching: false,
        questions: [...state.questions, nextQuestion],
        ...rest
      };
    }
    case GET_QUESTION_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case SET_ACTIVITY_MARKED:
      return {
        ...state,
        isMarked: true,
        streak: updateStreakWithResponse([...state.streak], action.response),
        isInitialQuestion: false
      };
    case SET_ACTIVITY_RESPONSE:
      return {
        ...state,
        userResponse: action.userResponse
      };
    case SET_ACTIVITY_NEXT:
      return {
        ...state,
        isLoading: true,
        isMarked: false,
        questions: state.questions.slice(state.questions.length - 1),
        userResponse: null,
        streak: state.streakAchieved ? [] : [...state.streak],
        loadNextQuestion: true
      };
    case SET_ACTIVITY_LOADED:
      return {
        ...state,
        isLoading: false,
        loadNextQuestion: false,
        startDate: new Date()
      };
    case SET_ACTIVITY_ACTIVE:
      return {
        ...state,
        isActivityActive: action.status
      };
    case RESET_ACTIVITY:
      return {
        ...state,
        isInitialQuestion: true,
        isLoading: true
      };
    default:
      return state;
  }
};

export default activityReducer;
