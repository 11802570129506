import React from 'react';
import classNames from 'classnames';
import InlineSvg from 'react-inlinesvg';
import { t } from '@lingui/macro';
import { Timeline, Tween } from 'react-gsap';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import css from './congratulations-text.scss';
import circle from '../../assets/images/ico_circle.svg';
import star from '../../assets/images/ico_star.svg';
import { EASE, TEXT_DURATION, TEXT_OFFSET } from './congratulations-utils';

const circles = [
  { className: [css.small, css.yellow], left: 11, top: 78 },
  { className: css.small, left: 9, top: 14 },
  { className: css.small, left: 99.5, top: 20 },
  { className: css.small, left: 87.5, top: 76 },
  { left: 6, top: 83 },
  { left: 89.5, top: 15 }
];
const stars = [
  { className: [css.small, css.rotate], left: 8.2, top: 78 },
  { className: [css.small, css.rotate], left: 95, top: 26 },
  { className: css.small, left: 97.5, top: 21 },
  { className: [css.small, css.yellow], left: 7, top: 19 },
  { className: [css.small, css.yellow], left: 85, top: 68 },
  { className: css.yellow, left: 4, top: 65 },
  { className: css.yellow, left: 91.5, top: 14 }
];

const fixedStars = [
  stars[0],
  stars[1],
  stars[2],
  stars[3],
  stars[6]
];

const CongratulationsText = () => {
  const { i18n } = useLocalisation();
  const congratulationsText = i18n._(t`congratulations!`);

  return (
    <Tween
      from={{
        y: -TEXT_OFFSET
      }}
      to={{
        y: 0
      }}
      duration={TEXT_DURATION}
      ease={EASE}
    >
      <div className={css['congratulations-text']}>
        <div className={css.decorations}>
          {circles.map(({ className, left, top }, i) => (
            <Timeline
              key={+i}
              target={
                <span className={classNames(css.circle, className)}>
                  <InlineSvg src={circle} alt="" />
                </span>
              }
            >
              <Tween
                from={{
                  left: `${left}%`,
                  top: `${top}%`,
                  scale: 0.4,
                  boxShadow: '0 0 0 2px #fed4a4, 0 0 0 5px #fed4a4 inset'
                }}
                to={{
                  scale: 1.3
                }}
                duration={TEXT_DURATION / 2}
                ease={EASE}
              />
              <Tween
                to={{
                  scale: 1,
                  boxShadow: '0 0 0 2px transparent, 0 0 0 5px transparent inset'
                }}
                duration={TEXT_DURATION / 2}
                ease={EASE}
              />
            </Timeline>
          ))}
          {stars.map(({ className, left, top }, i) => (
            <Timeline
              key={+i}
              target={
                <span className={classNames(css.star, className)}>
                  <InlineSvg src={star} alt="" />
                </span>
              }
            >
              <Tween
                from={{
                  left: `${left}%`,
                  top: `${top}%`,
                  scale: 0.4,
                  rotation: -45
                }}
                to={{
                  scale: 1.3,
                  rotation: 0
                }}
                duration={TEXT_DURATION}
                ease={EASE}
              />
              <Tween
                to={{
                  left: `${100 - left}%`
                }}
                duration={TEXT_DURATION / 4}
                ease={EASE}
              />
              <Tween
                to={{
                  left: `${left}%`,
                  scale: 1
                }}
                duration={TEXT_DURATION / 4}
                ease={EASE}
              />
            </Timeline>
          ))}
          {fixedStars.map(({ className, left, top }, i) => (
            <Timeline
              key={+i}
              target={
                <span className={classNames(css.star, className)}>
                  <InlineSvg src={star} alt="" />
                </span>
              }
            >
              <Tween
                from={{
                  left: `${left}%`,
                  top: `${top}%`,
                  opacity: 0
                }}
                to={{
                  opacity: 1
                }}
                delay={TEXT_DURATION}
                duration={TEXT_DURATION / 4}
                ease={EASE}
              />
              <Tween
                to={{
                  opacity: 0
                }}
                duration={TEXT_DURATION / 4}
                ease={EASE}
              />
              <Tween
                to={{
                  opacity: 1
                }}
                duration={TEXT_DURATION / 4}
                ease={EASE}
              />
            </Timeline>
          ))}
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 20">
          <path id="curve" d="M 0,20 A 10 2 0 0 1 100,20" fill="none" />
          <text textAnchor="middle">
            <textPath xlinkHref="#curve" startOffset="50%">
              {congratulationsText}
            </textPath>
          </text>
        </svg>

      </div>
    </Tween>
  );
};

export default CongratulationsText;
