exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".info-content---2vt1T{position:relative}.info-content---2vt1T .btn-icon---2eARG{background-color:#fff;width:40px;height:40px;border-radius:20px;padding:0}.info-content---2vt1T .btn-icon---2eARG span{display:flex;justify-content:center;align-items:center}@media(hover: hover),(-ms-high-contrast: none){.info-content---2vt1T .btn-icon---2eARG:hover{background-color:#b2e1e3}}.info-content---2vt1T .tip---2NnRp{position:absolute;left:20px;top:24%}", ""]);

// exports
exports.locals = {
	"info-content": "info-content---2vt1T",
	"btn-icon": "btn-icon---2eARG",
	"tip": "tip---2NnRp"
};