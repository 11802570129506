exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".crown-container---33EvO{display:block;position:relative;z-index:1}", ""]);

// exports
exports.locals = {
	"crown-container": "crown-container---33EvO"
};