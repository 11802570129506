import _ from 'lodash';
import { MODAL_CONSTRUCTED, MODAL_ACTIVATED } from './modal-actions';

export default function (
  state = [],
  action
) {
  switch (action.type) {
    case MODAL_CONSTRUCTED: {
      const {
        activate,
        content,
        customStyle,
        canvasOnClick
      } = action.payload;
      const modal = {
        activate,
        content,
        customStyle,
        canvasOnClick
      };

      return [...state, _.cloneDeep(modal)];
    }

    case MODAL_ACTIVATED: {
      const newState = state.slice();
      const modal = _.cloneDeep(newState.pop());

      if (modal && !modal.activate && action.bool) {
        modal.activate = true;
        newState.push(modal);
      }

      return newState;
    }

    default:
  }

  return state;
}
