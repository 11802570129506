import React from 'react';
import PropTypes from 'prop-types';
import { Timeline, Tween } from 'react-gsap';
import merit from '../../assets/images/hex_coin.svg';
import css from './gems-animation.scss';
import { COIN_DELAY, COIN_DURATION, EASE, TEXT_DURATION } from './congratulations-utils';

const GemsAnimation = ({ stepCount }) => (
  <Timeline
    target={
      <div className={css['gems-container']}>
        {[...Array(stepCount)].map((_, stepIndex) => (
          <Tween
            key={+stepIndex}
            from={{
              opacity: 0
            }}
            to={{
              opacity: 1
            }}
            duration={COIN_DURATION}
            delay={TEXT_DURATION + (stepIndex * (COIN_DELAY + COIN_DURATION))}
            ease={EASE}
          >
            <img src={merit} alt="" className={css.gem} />
          </Tween>
        ))}
      </div >
    }
    delay={TEXT_DURATION + COIN_DELAY + COIN_DURATION}
    repeat={stepCount - 1}
    repeatDelay={COIN_DELAY}
  >
    <Tween
      from={{
        opacity: 1
      }}
      to={{
        opacity: 0.7
      }}
      duration={COIN_DURATION / 2}
      ease={EASE}
    />
    <Tween
      to={{
        opacity: 1
      }}
      duration={COIN_DURATION / 2}
      ease={EASE}
    />
  </Timeline >
);

GemsAnimation.propTypes = {
  stepCount: PropTypes.number
};

export default GemsAnimation;
