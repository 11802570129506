import { STUDENT_PROGRESS_NARROW_TOGGLED } from './student-progress-actions';

const initialState = {
  narrowToggle: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STUDENT_PROGRESS_NARROW_TOGGLED: {
      return {
        narrowToggle: !state.narrowToggle
      };
    }

    default:
      return state;
  }
};
