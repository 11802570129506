import React, { Fragment } from 'react';
import { Trans } from '@lingui/react';
import { ModalGeneric } from '../../components/modal-content';
import { closeUpf } from '../../helpers/utils';
import errorImage from '../../assets/images/UPF_error.svg';
import css from './error-handling.scss';

const modalData = {
  subHeader: <Trans>Uh-oh...</Trans>,
  paragraph: (
    <Fragment>
      <Trans>Something went wrong with this question!</Trans><br /><br />
      <img className={css['error-boy']} src={errorImage} alt="" /><br />
      <Trans>Let your teacher know there is a problem with this quest.</Trans>
    </Fragment>
  ),
  buttons: [
    {
      text: <Trans>Exit to student console</Trans>,
      callBack: closeUpf,
      size: 'large'
    }
  ]
};

const invalidCourseModalProperties = {
  activate: true,
  content: <ModalGeneric modalData={modalData} />,
  canvasOnClick: false
};

export default invalidCourseModalProperties;
