exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".gem-holders---1c-W2{display:none;width:60px;background:#37abf2;background:linear-gradient(90deg, #004685 0%, #006eb1 51%, #004685 100%);position:absolute;right:0;border-radius:18px 0 0 18px;border:3px solid #e69e5a;border-right:none;top:20px;padding:5px 0;box-shadow:0 4px 0 0 rgba(0,0,0,.35)}.gem-holders---1c-W2 .gem-holder---yOGWB{width:34px;height:31px;margin:5px auto;position:relative}.gem-holders---1c-W2 .gem-holder---yOGWB span:first-child{position:absolute;width:100%;height:100%}.gem-holders---1c-W2 .gem-holder---yOGWB span:nth-child(2){position:absolute;width:80%;height:80%;left:11.5%;top:6%}.gem-holders---1c-W2 .arrow---QG1a3{display:flex;justify-content:center;align-items:center}.gem-holders---1c-W2 .arrow---QG1a3 svg{width:16px}@media only screen and (max-width: 800px){.gem-holders---1c-W2.narrow-toggle---36z0H{display:flex;flex-direction:column;align-items:center}}", ""]);

// exports
exports.locals = {
	"gem-holders": "gem-holders---1c-W2",
	"gem-holder": "gem-holder---yOGWB",
	"arrow": "arrow---QG1a3",
	"narrow-toggle": "narrow-toggle---36z0H"
};