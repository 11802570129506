import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'react-inlinesvg';
import { t } from '@lingui/macro';
import classNames from 'classnames';
import userIcon from '../../assets/images/icon_user.svg';
import previewIcon from '../../assets/images/icon_preview.svg';
import css from './profile.scss';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import { getValueFromUrl } from '../../helpers/utils';

const Profile = ({ firstName = '' }) => {
  const isPreview = getValueFromUrl('isPreview');
  const { i18n } = useLocalisation();
  const userName = isPreview ? i18n._(t`PREVIEW MODE`) : firstName;
  const iconType = isPreview ? previewIcon : userIcon;

  return (
    <div className={classNames(css.profile, { [css.truncate]: firstName.length > 10 })}>
      <InlineSVG src={iconType} className={css.icon} />
      <p className={css.text}>{userName}</p>
    </div>

  );
};

Profile.propTypes = {
  firstName: PropTypes.string
};

export default Profile;
