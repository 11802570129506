exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".flare---2B8Fi{display:block;position:absolute;z-index:20;width:100%;height:100%}.flare---2B8Fi .inner---HoaAP{width:100%;height:100%}.flare---2B8Fi .st0{fill:#fff}.flare---2B8Fi #round{opacity:.5}", ""]);

// exports
exports.locals = {
	"flare": "flare---2B8Fi",
	"inner": "inner---HoaAP"
};