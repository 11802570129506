exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".gem---2ahjf{width:100%;height:100%;position:absolute}.gem---2ahjf .flare1---2A8ZM{transform:scale(0.5) translate(-34%, 54%)}.gem---2ahjf .flare2---2ALCg{width:100%;height:100%;position:absolute;z-index:30;transform:scale(0.2) translate(120%, -204%) rotate(25deg)}.gem---2ahjf .gem-white---348wy{width:100%;height:100%;z-index:10;position:absolute;display:block;opacity:.5}.gem---2ahjf .gem-bkgd---3tWEM{width:100%;height:100%;z-index:0;position:absolute;display:block}", ""]);

// exports
exports.locals = {
	"gem": "gem---2ahjf",
	"flare1": "flare1---2A8ZM",
	"flare2": "flare2---2ALCg",
	"gem-white": "gem-white---348wy",
	"gem-bkgd": "gem-bkgd---3tWEM"
};