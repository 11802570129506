exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".student-progress---2QTg3{width:185px;margin:0 40px 0 5px;display:flex;flex-direction:column;border-radius:16px 16px 16px 0;z-index:100;transition:all .5s ease-out;position:relative;margin-top:10px}@media only screen and (max-width: 800px){.student-progress---2QTg3{position:absolute;right:0;margin:0;border-radius:unset}}", ""]);

// exports
exports.locals = {
	"student-progress": "student-progress---2QTg3"
};