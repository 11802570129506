import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextToSpeech from '@3plearning/tts';
import classNames from 'classnames';
import css from './activity.scss';
import { BtnIcon } from '../../components/buttons';
import { setActivityResponse, activityLoaded } from './activity-actions';
import Ducs from '../ducs/ducs';
import { disableSpinner } from '../mle/mle-actions';
import { LEARNOSITY_SPINNER } from '../../helpers/constants';
import Congratulations from '../../components/congratulations/congratulations';
import { getTextSpeechConfig } from './activity-utils';
import { activateError } from '../error-handling/error-handling-actions';
import keyboardIcon from '../../assets/images/ico-keypad.svg';
import Conquest from '../conquest/conquest';

let resetCounter = 0;

const Activity = () => {
  const dispatch = useDispatch();
  const { conquestReducer } = useSelector(state => state);
  const {
    isMarked,
    questions,
    stepsCompleted,
    loadNextQuestion,
    isLoading,
    isInitialQuestion,
    userResponse
  } = useSelector(state => state.activityReducer);
  const { description, stepCount } = useSelector(state => state.mleReducer || {});

  const [textSpeechConfig, setTextSpeechConfig] = useState(null);
  const [isKeyboardActive, setKeyboardActive] = useState(false);
  const [showKeyboardIcon, setShowKeyboardIcon] = useState(false);

  const handleChange = (response) => {
    dispatch(setActivityResponse(response));
  };

  const showKeyboardEl = (isShow) => {
    const el = window.document.querySelector('.lrn-formula-keyboard');
    if (el) {
      if (isShow) {
        el.classList.remove('hidden');
      } else {
        el.classList.add('hidden');
      }
    }
  };

  const handleKeyboard = () => {
    if (!isKeyboardActive) {
      const el = window.document.querySelector('.js-ducs .lrn_focusable');
      if (el) el.focus();
      showKeyboardEl(true);
      setKeyboardActive(!isKeyboardActive);
    } else {
      showKeyboardEl(false);
      setKeyboardActive(!isKeyboardActive);
    }
  };

  const handleLoad = () => {
    dispatch(activityLoaded());
    dispatch(disableSpinner(LEARNOSITY_SPINNER));
    if (window.document.querySelector('.lrn-formula-keyboard')) {
      setShowKeyboardIcon(true);
    } else {
      setShowKeyboardIcon(false);
    }
  };

  useEffect(() => {
    (async () => {
      const config = await getTextSpeechConfig();
      setTextSpeechConfig(config);
    })();
  }, []);

  const isJourneyCompleted = stepCount && (stepsCompleted === stepCount);
  const canLoadDucs = stepCount && questions[0] && !isJourneyCompleted;

  const renderingEngine = () => {
    if (conquestReducer.isNewContent && !isJourneyCompleted) {
      return <Conquest />;
    } else if (canLoadDucs) {
      return (
        <Ducs
          {...questions[0]}
          isMarked={isMarked}
          onChange={handleChange}
          onLoad={handleLoad}
          loadNext={loadNextQuestion}
          userResponse={userResponse}
          activateAutoFocus
        />
      );
    }
    return <React.Fragment />;
  };

  useEffect(() => {
    if (!isLoading) {
      resetCounter += 1;
      showKeyboardEl(isKeyboardActive);
    }

    if (!canLoadDucs) {
      dispatch(disableSpinner(LEARNOSITY_SPINNER));
    }
  }, [isMarked, isLoading, canLoadDucs]);

  useEffect(() => {
    if (isJourneyCompleted) {
      dispatch(disableSpinner(LEARNOSITY_SPINNER));
    }
  }, [stepsCompleted, stepCount]);

  return (
    <div className={classNames(css.activity, { [css.completed]: isJourneyCompleted })}>
      {canLoadDucs && textSpeechConfig && (!isLoading || !isInitialQuestion) && !conquestReducer.isNewContent &&
        <TextToSpeech
          reset={resetCounter}
          textSpeechConfig={textSpeechConfig}
          onError={() => { dispatch(activateError({ status: 500 })); }}
          className={css.tts}
        />
      }
      {canLoadDucs && showKeyboardIcon && !conquestReducer.isNewContent && <BtnIcon
        icon={keyboardIcon}
        className={classNames(css['keyboard-btn'], { [css.active]: isKeyboardActive })}
        callBack={handleKeyboard}
      />}
      <div className={classNames(css['inner-scroll'], { [css.completed]: isJourneyCompleted })}>
        { renderingEngine() }
        {
          isJourneyCompleted && <Congratulations description={description} stepCount={stepCount} />
        }
      </div>
    </div>
  );
};

export default Activity;
