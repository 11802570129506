import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Timeline, Tween } from 'react-gsap';
import _ from 'lodash';
import css from './crown-whole.scss';
import crown1 from '../../../assets/images/1step_UPF.svg';
import crown2 from '../../../assets/images/2step_UPF.svg';
import crown3 from '../../../assets/images/3step_UPF.svg';
import crown4 from '../../../assets/images/4step_UPF.svg';
import crown5 from '../../../assets/images/5step_UPF.svg';
import gemsOrigin from './gems-origin';
import Gem from '../gem/gem';
import StepCompletedBanner from '../step-completed-banner/step-completed-banner';

const CrownWhole = () => {
  const { mleReducer, activityReducer } = useSelector(state => state);
  const stepCount = _.get(mleReducer, 'stepCount');
  const stepsCompleted = _.get(activityReducer, 'stepsCompleted');
  const { narrowToggle = false } = useSelector(state => state.studentProgressReducer);

  const crownPngs = [crown1, crown2, crown3, crown4, crown5];
  const crownPng = crownPngs[stepCount - 1];

  const [firstLanded, setFirstLanded] = useState(true);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter(counter + 1);
    if (counter > 1) {
      setFirstLanded(false);
    }
  }, [stepsCompleted]);

  return stepCount && stepsCompleted !== undefined ? (
    <div
      className={
        classNames(
          css[`crown${stepCount}`],
          css['crown-whole'],
          { [css['narrow-toggle']]: narrowToggle }
        )
      }
    >
      {
        [...Array(stepCount)].map((item, count) =>
          (
            stepsCompleted === count + 1 && !firstLanded ? (
              <Timeline
                key={`gem${count + 1}`}
                target={
                  <div
                    className={css.gem}
                  >
                    <Gem
                      stepCount={stepCount}
                      anim
                    />
                  </div>
                }
              >
                <Tween
                  from={{
                    transform: 'scale(0.2)',
                    left: 0,
                    top: '120%',
                    opacity: 1
                  }}
                  to={{
                    transform: 'scale(0.5)',
                    top: '-16%'
                  }}
                  duration={0.7}
                  ease={'Back.easeOut'}
                />
                <Tween
                  to={gemsOrigin[`crown${stepCount}`][`gem${count + 1}`]}
                  delay={2}
                  duration={0.5}
                  ease={'Power4.easeOut'}
                />
              </Timeline>
            ) : (
              <div
                style={
                  count < stepsCompleted ?
                    gemsOrigin[`crown${stepCount}`][`gem${count + 1}`] :
                    { opacity: 0 }
                }
                key={`gem${count + 1}`}
                className={css.gem}
              >
                <Gem
                  stepCount={stepCount}
                />
              </div>
            )
          )
        )
      }
      <div
        className={css.frame}
      >
        <img
          src={crownPng}
          alt={`crown${stepCount}`}
          className={css.image}
        />
      </div>
      <StepCompletedBanner className={css.banner} />
    </div>
  ) : <Fragment />;
};

export default CrownWhole;
