import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import Resources from '../../../helpers/resources';
import { getValueFromUrl } from '../../../helpers/utils';
import { getDifferenceInSeconds } from '../../activity/activity-utils';

export const useValidate = () => {
  const { conquestReducer, assignmentIdReducer, activityReducer } = useSelector(state => state);
  const { startDate, questions } = activityReducer;
  const { answer } = conquestReducer;

  const validate = async () => {
    const res = await Resources.saveQuestionResultQtc
      .post()
      .withBody({
        assignmentId: assignmentIdReducer,
        questionId: questions[0].id,
        studentAnswer: answer,
        locale: getValueFromUrl('locale'),
        productId: 1,
        timeToAnswerSec: getDifferenceInSeconds({
          startDate,
          endDate: new Date()
        })
      })
      .send();

    return res;
  };

  return { ...useMutation({
    mutationFn: validate
  }) };
};
