import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import CrownWhole from '../crown-whole/crown-whole';
import GemHolders from '../gem-holders/gem-holders';
import css from './crown-container.scss';

const CrownContainer = () => {
  const { narrowToggle } = useSelector(state => state.studentProgressReducer);

  return (
    <div
      className={classNames(
        css['crown-container'],
        { [css['narrow-toggle']]: narrowToggle }
      )}
    >
      <CrownWhole />
      <GemHolders />
    </div>
  );
};

export default CrownContainer;
