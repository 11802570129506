import pseudoLocalise from './pseudo-localise';

const DEBUG = false;

function debug(...args) {
  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
}

function error(...args) {
  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.error(...args);
  }
}

export function loadLinguiCatalogs(baseUrl, locales) {
  // Before we can continue, we need to get the message JSON for each locale
  // from the CDN.
  //
  // Return a promise that will resolve once we have all of them and have
  // compiled them into the data that Lingui requires.

  const catalogPromises = locales.map(locale => loadCatalog(baseUrl, locale));

  return new Promise((resolve) => {
    Promise.all(catalogPromises)
      .then((catalogs) => {
        const localeCatalogs = locales.reduce((lc, locale, i) => {
          lc[locale] = catalogs[i];

          return lc;
        }, { });

        resolve(localeCatalogs);
      });
  });
}

function loadCatalog(baseUrl, locale) {
  // Return a promise that will resolve once we've retrieved the message JSON for the
  // specified locale.
  //
  // What we return is the compiled version of that JSON.
  //
  // If, for some reason, an error occurs retrieving the JSON for the specified locale,
  // we attempt to retrieve the en-AU file, instead, which should always exist.  If so,
  // we use it.  If not, well there's not much we can do, other than log a message that
  // something went wrong.

  return new Promise((resolve) => {
    window.fetch(`${baseUrl}/${locale}.json`)
      .then(response => response.json())
      .then((translations) => {
        debug('loadCatalog() - translations =', JSON.stringify(translations, null, 2));
        const compiled = compile(locale, translations);

        resolve(compiled);
      })
      .catch((e) => {
        error(`loadCatalog() - received an error loading ${locale}; replacing with en-AU. Error was...`,
          e);

        window.fetch(`${baseUrl}/en-AU.json`)
          .then(response => response.json())
          .then((translations) => {
            debug('loadCatalog() - translations =', JSON.stringify(translations, null, 2));
            const compiled = compile(locale, translations);

            resolve(compiled);
          }).catch((error2) => {
            e(`loadCatalog() - the fallback to en-AU failed with error... ${error2}`);
            resolve({});
          });
      });
  });
}

// This plurals() function is borrowed straight from the
// code generated by "lingui compile".

function pluralsFunction(n, ord) {
  const s = String(n).split('.');
  const t0 = Number(s[0]) === n;
  const n10 = t0 && s[0].slice(-1);
  const n100 = t0 && s[0].slice(-2);

  if (ord) {
    if (n10 === '1' && n100 !== '11') {
      return 'one';
    } else if (n10 === '2' && n100 !== '12') {
      return 'two';
    } else if (n10 === '3' && n100 !== '13') {
      return 'few';
    }

    return 'other';
  }

  const v0 = !s[1];

  return n === '1' && v0 ? 'one' : 'other';
}

export function compile(locale, json) {
  debug('compile() - locale =', locale, 'json =', JSON.stringify(json, null, 2));

  if (locale === 'en-PL') {
    json = pseudoLocalise(json);
  }

  const languageData = { plurals: pluralsFunction };
  const messages = compileMessages(locale, json);

  return { languageData, messages };
}


function compileMessages(locale, json) {
  debug('compileMessages() - json =', JSON.stringify(json, null, 2));

  const pluralPattern = /^{(\w+)\s*,\s*plural,\s*(.*)\s*}\s*$/;
  let match;

  return Object.keys(json).reduce((messages, key) => {
    const message = json[key];
    let compiled;

    match = key.match(pluralPattern);

    if (match !== null) {
      debug(`compileMessages() - found plural with key = "${key}" and message = "${message}"`);

      match = message.match(pluralPattern);

      if (match === null) {
        if (locale !== 'en-PL') {
          error(`compileMessage() - [${locale}] replacement value "${message}" ` +
                        `for plural "${key}" is inconsistent.`);
        }
      } else {
        const name = match[1];
        const options = match[2];

        compiled = compilePluralMessage(name, options);
      }
    } else {
      debug(`compileMessages() - found simple message with key = "${key}" and value = "${message}"`);

      compiled = compileSimpleMessage(locale, key, message);
    }

    messages[key] = compiled;

    return messages;
  }, {});
}

function compilePluralMessage(name, options) {
  // An example value for 'options' would be
  //
  //   one {Showing 1 certificates} other {Showing {resultsNum} certificates}
  //
  // where the word before each {} is the cardinality and the contents
  // of the immediately following {} specifies the text to be used for that
  // cardinality.

  const cardinalityMessages = {};
  let cardinality;
  let message;
  let remainder;

  while (options.length > 0) {
    debug(`compilePluralMessage() - options = ${options}`);

    // In theory, we need a parser to process this, but fortunately there are
    // only two cases: one with the name of the numeric parameter embedded,
    // and the other that's a plain text string.
    //
    // By making sure to match the more complex pattern first, we can do this
    // with regular expressions.
    //
    // Try to match something like the following, that contains an embedded plural
    //
    //    other {Showing {resultsNum} word lists}

    let match = options.match(/^\s*(\w+)\s*{([^{]+\s*){([^{}]+)}(.*)}(.*)$/);

    if (match !== null) {
      cardinality = match[1];

      const prefix = match[2];
      const parameter = match[3];
      const suffix = match[4];

      remainder = match[5];

      debug(`   cardinality = "${cardinality}" prefix = "${prefix}" parameter = "${parameter}"` +
                ` suffix = "${suffix}" remainder = "${remainder}"`);

      cardinalityMessages[cardinality] = { prefix, parameter, suffix };
    } else {
      // The {} didn't contain an embedded parameter, so we expect the structure
      // to contain the cardinality and the {} to contain a pure text string. The
      // cardinality will be either a string like "one" or "other", or an explicit
      // value like "=0".  For example,
      //
      //    one {Showing 1 word list}
      //    =0 {There are no word lists}

      match = options.match(/^\s*(=(?:\d+)|\w+)\s*{([^}]+)}(.*)$/);

      if (match !== null) {
        cardinality = match[1];
        message = match[2];
        remainder = match[3];

        debug(`   cardinality = "${cardinality}" message = "${message}" remainder = "${remainder}"`);

        match = cardinality.match(/^=(\d+)$/);

        if (match) {
          cardinality = parseInt(match[1], 10);

          debug(`     the cardinality is an explicit number (${cardinality})`);
        }

        cardinalityMessages[cardinality] = message;
      } else {
        // This key doesn't seem to match either of the cases, so we need to give
        // up at this point.  In theory, this should never happen, but just in case,
        // we'll log an error, so we're alerted to the issue in the browser console.

        error(`compilePluralMessage() - failed to match...${options}`);
        break;
      }
    }

    options = remainder;
  }

  return buildPluralFormatter(name, cardinalityMessages);
}

function compileSimpleMessage(locale, key, message) {
  let compiled;

  const parameters = key.match(/{([^}]+)}/g);

  if (parameters === null) {
    compiled = message;

    if (message.length === 0 && locale !== 'en-PL') {
      error(`compileSimpleMessage() [${locale}] - WARNING: No translation for "${key}"`);
    }
  } else {
    compiled = buildSimpleFormatter(message);
  }

  return compiled;
}

function buildSimpleFormatter(message) {
  debug(`buildSimpleFormatter() - message = "${message}"`);

  // The "message" parameter will be a string, something like...
  //
  //   'P1 = {param1} and P2 = {param2}'
  //
  // We want to return a function that looks like this
  //
  //   function (a) {
  //       return ["P1 = ", a("param1"), " and P2 = ", a("param2")]
  //   }
  //
  // In other words, the returned function needs to accept a callback
  // and construct an array where any fixed text pieces are left
  // alone and any parameter names are passed to the callback before
  // inclusion into the array.
  //
  // Some {} references contain multiple parameters; for example
  //
  //    'Today is {today, date}.'
  //
  // The 'date' in this example tells Lingui that the parameter
  // 'today' is a date and should be formatted appropriately.
  //
  // The code we need to generate involves passing all of the parameters,
  // in order, as string parameters to the callback:
  //
  //   function (a) {
  //       return ["Today is ", a("today", "date"), "."];
  //   }
  //
  // So, split the contents of the {} on commas and spread the resulting
  // array to form the parameters for the callback.

  const parameters = message.split(/{([^}]+)}/);

  return function formatter(callback) {
    debug('buildSimpleFormatter().formatter() - parameters =',
      JSON.stringify(parameters, null, 2));

    return parameters.reduce((values, value, index) => {
      // If it's an even-indexed item, it's just a fixed piece of text, so we
      // want its value, as is.
      //
      // If it's an odd-indexed item, (which will be the names of one or more
      // parameters from the {}), we need to pass it through the callback and
      // use the returned value.

      const item = (index % 2 === 0) ? value : executeCallback(value);

      values.push(item);

      return values;
    }, []);

    function executeCallback(value) {
      const callParameters = value.trim().split(/\s*,\s*/);

      debug('buildSimpleFormatter().executeCallback() - callParameters =',
        JSON.stringify(callParameters, null, 2));

      return callback(...callParameters);
    }
  };
}

function buildPluralFormatter(name, cardinalityMessages) {
  // What we expect to receive is the name of the parameter, along with an object
  // containing a key for each cardinality, which maps to the corresponding data we
  // need to pass to the callback.
  //
  // This is slightly trickier than a simple formatter, in that the last parameter
  // in the call to the callback needs to be an object containing each of the
  // cardinality keys, mapping to a value that is the same kind of array that would
  // be returned for a simple formatter.
  //
  // In other words, it involves a second level of calls to the callback. For example,
  // what started out as the following, in the messages JSON:
  //
  //  '{resultsNum, plural, one {Showing 1 word list} other {Showing {resultsNum} word lists}}'
  //
  // needs to be turned into a function that looks like:
  //
  //    function (a) {
  //        return [a('resultsNum', 'plural', {
  //            one: 'Showing 1 word list',
  //            other: ['Showing ', a('resultsNum'), ' word lists']
  //       })];
  //    }

  return function formatter(callback) {
    const values = {};

    Object.keys(cardinalityMessages).forEach((cardinality) => {
      const message = cardinalityMessages[cardinality];

      if (message.prefix) {
        // This looks like { prefix, parameter, suffix }

        const { prefix, parameter, suffix } = message;

        values[cardinality] = [prefix, callback(parameter), suffix];
      } else {
        // The value for this cardinality is pure text

        values[cardinality] = message;
      }
    });

    return [callback(name, 'plural', values)];
  };
}
