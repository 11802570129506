export default function pseudoLocalise(json) {
  return Object.keys(json).reduce((pseudoJson, key) => {
    pseudoJson[key] = pseudo(json[key]);

    return pseudoJson;
  }, {});

  function pseudo(text) {
    let match = text.match(/({\s*[^{]+\s*,\s*plural\s*,\s*)(.*)\s*(})/);
    const pseudoLocalised = [];
    let pieces;
    let prefix;

    if (match) {
      // This is something like
      //
      //    {resultsNum, plural, one {Showing 1 certificate} other {Showing {resultsNum} certificates}}

      prefix = match[1];

      let options = match[2];
      const suffix = match[3];

      pseudoLocalised.push(prefix);

      while (options.length > 0) {
        let cardinality;

        match = options.match(/^\s*([^{]+?\s*){([^{]+?\s*){([^{}]+?)}(.*)}(.*)$/);

        if (match) {
          cardinality = match[1];

          prefix = match[2];
          const parameter = match[3];

          options = match[5];
          const optionSuffix = match[4];

          pseudoLocalised.push(' ');
          pseudoLocalised.push(cardinality);
          pseudoLocalised.push('{');
          pseudoLocalised.push(pseudify(prefix));
          pseudoLocalised.push(`{${parameter}}`);
          pseudoLocalised.push(pseudify(optionSuffix));
          pseudoLocalised.push('}');
        } else {
          match = options.match(/^\s*([^{]+?\s*){([^}]+?)}(.*)$/);

          if (match !== null) {
            cardinality = match[1];

            const message = match[2];

            options = match[3];

            pseudoLocalised.push(' ');
            pseudoLocalised.push(cardinality);
            pseudoLocalised.push('{');
            pseudoLocalised.push(pseudify(message));
            pseudoLocalised.push('}');
          } else {
            // eslint-disable-next-line no-console
            console.log(`pseudo.pseudoLocalise() - failed to match... ${options}`);
            break;
          }
        }
      }

      pseudoLocalised.push(suffix);
    } else {
      pseudify(text);
    }

    function pseudify(item) {
      pieces = item.split(/({[^}]+})/);

      pieces.forEach((piece, i) => {
        // eslint-disable-next-line no-use-before-define
        pseudoLocalised.push((i % 2 === 0) ? piece.split('').map(c => PSEUDO_MAP[c] || c).join('') : piece);
      });
    }

    const pseudofied = pseudoLocalised.join('');

    return pseudofied;
  }
}

const PSEUDO_MAP = {
  a: 'ȧ',
  A: 'Ȧ',
  b: 'ƀ',
  B: 'Ɓ',
  c: 'ƈ',
  C: 'Ƈ',
  d: 'ḓ',
  D: 'Ḓ',
  e: 'ḗ',
  E: 'Ḗ',
  f: 'ƒ',
  F: 'Ƒ',
  g: 'ɠ',
  G: 'Ɠ',
  h: 'ħ',
  H: 'Ħ',
  i: 'ī',
  I: 'Ī',
  j: 'ĵ',
  J: 'Ĵ',
  k: 'ķ',
  K: 'Ķ',
  l: 'ŀ',
  L: 'Ŀ',
  m: 'ḿ',
  M: 'Ḿ',
  n: 'ƞ',
  N: 'Ƞ',
  o: 'ǿ',
  O: 'Ǿ',
  p: 'ƥ',
  P: 'Ƥ',
  q: 'ɋ',
  Q: 'Ɋ',
  r: 'ř',
  R: 'Ř',
  s: 'ş',
  S: 'Ş',
  t: 'ŧ',
  T: 'Ŧ',
  v: 'ṽ',
  V: 'Ṽ',
  u: 'ŭ',
  U: 'Ŭ',
  w: 'ẇ',
  W: 'Ẇ',
  x: 'ẋ',
  X: 'Ẋ',
  y: 'ẏ',
  Y: 'Ẏ',
  z: 'ẑ',
  Z: 'Ẑ'
};

