exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".keypad-container---3xsU7{width:460px;height:280px;background-color:#fff;position:absolute;right:20px;top:20%;border-radius:10px;padding:10px;filter:drop-shadow(rgba(0, 25, 55, 0.3) 0 12px 16px)}.keypad-container---3xsU7 .ML__keyboard.is-visible{width:auto}.keypad-container---3xsU7 .ML__keyboard.is-visible .MLK__backdrop{background-color:rgba(0,0,0,0);position:static;display:inline}.keypad-container---3xsU7 .ML__keyboard.is-visible .MLK__tooltip{color:gray}.keypad-container---3xsU7 .ML__keyboard.is-visible .MLK__keycap,.keypad-container---3xsU7 .ML__keyboard.is-visible .shift,.keypad-container---3xsU7 .ML__keyboard.is-visible .action{color:gray;background-color:rgba(0,0,0,0);border:1px solid rgba(52,119,199,.4)}.keypad-container---3xsU7 .keypad-container-header---1xizK{height:40px;padding:6px 0 0 6px;cursor:grabbing;background-color:#f4f4f4;border-radius:8px 8px 0 0}.keypad-container---3xsU7 .drag-icon---3bkKf{border:1px solid #007acc;padding:2px;border-radius:50%}", ""]);

// exports
exports.locals = {
	"keypad-container": "keypad-container---3xsU7",
	"keypad-container-header": "keypad-container-header---1xizK",
	"drag-icon": "drag-icon---3bkKf"
};