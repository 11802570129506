import {
  GET_DUCS_CONFIG_REQUESTED,
  GET_DUCS_CONFIG_SUCCESS,
  GET_DUCS_CONFIG_ERROR,
  SET_DUCS_INSTANCE_ID
} from './ducs-actions';

export const initialState = {
  isFetching: false,
  url: null,
  config: null,
  instanceId: null
};

const ducsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DUCS_CONFIG_REQUESTED:
      return {
        ...state,
        isFetching: true
      };
    case GET_DUCS_CONFIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        url: action.url,
        config: action.config
      };
    case GET_DUCS_CONFIG_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case SET_DUCS_INSTANCE_ID:
      return {
        ...state,
        instanceId: action.instanceId
      };
    default:
      return state;
  }
};

export default ducsReducer;
