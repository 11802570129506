import { useState } from 'react';
import Resources from '../../../helpers/resources';

export const useReadAloud = () => {
  const [readAloudConfig, setReadAloudConfig] = useState({
    enabled: false,
    gatewayUrl: window._env_.ReadAloudGatewayUrl,
    token: Resources.env.authToken,
    locale: 'en-AU'
  });

  const onLocaleChange = (locale) => {
    setReadAloudConfig({
      ...readAloudConfig,
      locale
    });
  };

  const onToggleEnableState = (enabled) => {
    setReadAloudConfig({
      ...readAloudConfig,
      enabled: !enabled
    });
  };

  return { readAloudConfig, onLocaleChange, onToggleEnableState };
};
