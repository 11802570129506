import React, { useEffect } from 'react';
import { Button, HStack, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import Info from '../info/info';
import InfoFeedback from '../info-feedback/info-feedback';
import css from './footer-qtc.scss';
import { useValidate } from './hooks/use-validate';
import { resetConquestState, validateAnswer } from '../conquest/conquest.actions';
import { getQuestionSuccess, markActivity, nextActivity } from '../activity/activity-actions';
import { enableSpinner, disableSpinner } from '../mle/mle-actions';
import { LEARNOSITY_SPINNER } from '../../helpers/constants';
import { useBadStreak } from './hooks/use-bad-streak';


export const FooterQtc = () => {
  const dispatch = useDispatch();
  const { activityReducer, conquestReducer, mleReducer } = useSelector(state => state);
  const { questions, stepsCompleted } = activityReducer;
  const { isReady } = conquestReducer;
  const { stepCount } = mleReducer || {};
  const { mutate: validate, isSuccess, data } = useValidate();

  useBadStreak();

  useEffect(() => {
    if (isSuccess) {
      dispatch(disableSpinner(LEARNOSITY_SPINNER));
      dispatch(validateAnswer(data.data.validation.validationResult));
      dispatch(markActivity(data.data.validation.validationResult.isCorrect));
      dispatch(getQuestionSuccess(data.data));
    }
  }, [isSuccess]);

  const checkMyAnswerHandler = () => {
    dispatch(enableSpinner(LEARNOSITY_SPINNER));
    validate();
  };

  const nextHandler = () => {
    dispatch(resetConquestState());
    dispatch(getQuestionSuccess(data.data));
    dispatch(nextActivity());
  };

  const saveCloseHandler = () => {
    if (window.history.length === 1) {
      window.close();
    } else {
      window.history.back();
    }
  };

  return (
    <HStack className={css['footer-qtc']} >
      <HStack className={css['activity-side']} >
        <HStack className={css['info-stack']} spacing="20px">
          <Info />
          <InfoFeedback
            questions={questions}
          />
        </HStack>
        <Box>
          {
            conquestReducer.validation ? (
              <Button
                variant="mleInvert"
                isDisabled={stepsCompleted === stepCount}
                onClick={nextHandler}
              >
                <Trans>Next</Trans>
              </Button>
            ) : (
              <Button
                variant="mleInvert"
                isDisabled={!isReady}
                onClick={checkMyAnswerHandler}
              >
                <Trans>Check my answer</Trans>
              </Button>
            )
          }
        </Box>
      </HStack>
      <Box className={css['progress-side']} >
        <Button
          variant="mle"
          onClick={saveCloseHandler}
        >
          <Trans>Save and close</Trans>
        </Button>
      </Box>
    </HStack>
  );
};
